<template>
  <div>
      <v-row>
        <v-col>
          <span class="text-h4 mb-5 green--text">
            <!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->{{ docType }}
          </span>

          <span class="green--text ml-10" v-if="!onCreate">
            <v-icon class="mb-2" color="green" left>mdi-file</v-icon>
            {{ onCreate ? '' : form.number }}
          </span>
        </v-col>

        <v-col class="text-right">
          <v-btn class="ml-5" small color="grey" outlined @click="close()"
            >ปิดหน้าต่าง</v-btn
          >
          <v-btn
            class="ml-5"
            color="primary white--text"
            small
            @click="save()"
            :disabled="loading"
          >
            บันทึกข้อมูล
          </v-btn>
          <PrintDocument
            :key="printKey"
            v-if="form.number && list.setting.receipt_printing_format == 0"
            :docTypeId="docTypeId"
            :type="docType"
            :data="dataPrint.main_bill"
            :data2="dataPrint.split_bill"
            ref="printDocument"
            :disabled="!form.number"
            :firstCreated="true"
          >
            <v-btn
              class="ml-5"
              small
              color="primary"
              outlined
              :disabled="!form.number"
              :loading="loading"
            >
              พิมพ์ <v-icon right>mdi-printer</v-icon>
            </v-btn>
          </PrintDocument>
          <PrintDocument9inc
            :key="printKey"
            v-if="form.number && list.setting.receipt_printing_format == 2"
            :docTypeId="docTypeId"
            :type="docType"
            :data="dataPrint.main_bill"
            :data2="dataPrint.split_bill"
            ref="printDocument"
            :disabled="!form.number"
            :firstCreated="true"
          >
            <v-btn
              class="ml-5"
              small
              color="primary"
              outlined
              :disabled="!form.number"
              :loading="loading"
            >
              พิมพ์ <v-icon right>mdi-printer</v-icon>
            </v-btn>
          </PrintDocument9inc>
          <PrintDocument80mm
            :key="printKey"
            v-if="form.number && list.setting.receipt_printing_format == 1"
            :docTypeId="docTypeId"
            :type="docType"
            :data="dataPrint.main_bill"
            :data2="dataPrint.split_bill"
            ref="printDocument"
            :disabled="!form.number"
            :firstCreated="true"
          >
            <v-btn
              class="ml-5"
              small
              color="primary"
              outlined
              :disabled="!form.number"
              :loading="loading"
            >
              พิมพ์ <v-icon right>mdi-printer</v-icon>
            </v-btn>
          </PrintDocument80mm>

          <PrintDocumentFull
              :key="printKey"
              v-if="form.number && list.setting.receipt_printing_format == 3"
              :docTypeId="docTypeId"
              :type="docType"
              :data="dataPrint.main_bill"
              :data2="dataPrint.split_bill"
              ref="printDocument"
              :disabled="!form.number"
            >
              <v-btn
                class="ml-5"
                small
                color="primary"
                outlined
                :disabled="!form.number"
                :loading="loading"
              >
                พิมพ์ <v-icon right>mdi-printer</v-icon>
              </v-btn>
            </PrintDocumentFull>

          <v-btn
            class="ml-5"
            small
            color="error"
            outlined
            @click="pay"
            :disabled="form.status != 0 || onCreate || loading"
            :loading="loading"
          >
            คืนเงิน
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="loading">
        <v-progress-linear color="green" indeterminate class="text-center text-h6 white--text py-5" :height="60">กำลังโหลดข้อมูล กรุณาคอยสักครู่...</v-progress-linear>
      </div>
    <div v-if="!loading">

      <v-row>
        <v-col cols="12" class="text-right">
          <span class="text-h4 dark--text mr-5">รวมจำนวนเงินทั้งสิ้น</span>
          <span class="text-h2 green--text">{{
            net_price | numeral('0,0.00')
          }}</span>
        </v-col>

        <v-col cols="3">
          <v-text-field
            name="number"
            label="เลขที่ใบอ้างอิง"
            :value="onCreate ? form.number : form.reference_document"
            disabled
            outlined
            dense
          />

          <v-autocomplete
            v-model="approvals"
            :items="list.approval"
            :search-input.sync="form.approval"
            return-object
            item-text="name"
            item-value="_id"
            outlined
            dense
            label="ชื่อพนักงาน"
            autocomplete="off"
            @change="
              (val) => {
                getApprovalById(val._id);
              }
            "
            auto-select-first
            hide-details
            @keydown="handleApproval()"
            :disabled="isUpdate"
            class="mb-6"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.name }}</template
            >
          </v-autocomplete>

          <v-text-field
            name="project_name"
            v-model="form.project_name"
            label="ชื่อโปรเจ็ค"
            outlined
            dense
            autocomplete="on"
          />
        </v-col>
        <v-col cols="5" offset="0" style="font-size: 16px" align="center">
          <div v-if="this.form.customer_name">
            <p class="error--text" v-if="this.car_tax.popup_massage[0] != ''">
              {{ this.car_tax.popup_massage[0] }}
            </p>
            <p class="error--text" v-if="this.car_tax.popup_massage[1] != ''">
              {{ this.car_tax.popup_massage[1] }}
            </p>
            <p class="error--text" v-if="this.car_tax.popup_massage[2] != ''">
              {{ this.car_tax.popup_massage[2] }}
            </p>
            <p class="error--text" v-if="this.car_tax.popup_massage[3] != ''">
              {{ this.car_tax.popup_massage[3] }}
            </p>
            <p class="error--text" v-if="this.car_tax.popup_massage[4] != ''">
              {{ this.car_tax.popup_massage[4] }}
            </p>
            <p class="error--text" v-if="this.car_tax.popup_massage[5] != ''">
              {{ this.car_tax.popup_massage[5] }}
            </p>
          </div>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col cols="5">
              <v-row class="border-white" dense>
                <v-col cols="12">วันที่ออกใบ</v-col>
                <v-col cols="12">วันครบกำหนด</v-col>
              </v-row>
            </v-col>
            <v-col cols="7">
              <v-row class="border-grey" dense>
                <v-col cols="6">
                  {{ formatDate(form.date_of_issue) }}
                </v-col>
                <v-col cols="6" class="pl-5">
                  {{ form.date_of_issue | moment($formatTime) }}
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu_due_date"
                    v-model="menu.due_date"
                    :close-on-content-click="false"
                    :return-value.sync="form.due_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="isUpdate"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ formatDate(form.due_date) }}
                      </span>
                    </template>
                    <v-date-picker
                      v-model="form.due_date"
                      no-title
                      scrollable
                      locale="th"
                      @change="$refs.menu_due_date.save(form.due_date)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card outlined>
            <v-card-text class="px-5">
              <v-row class="pa-0">
                <v-col cols="3">
                  <div class="text-h5 primary--text">
                    1. ข้อมูลรถ
                  </div>
                </v-col>
                <v-col>
                  <span
                    class="layout-upload pointer primary--text"
                    @click="
                      (dialogEditDocumentData = {
                        document_id: form._id,
                        plate_character: temp_car.plate_character,
                        plate_number: temp_car.plate_number,
                        car_plate: temp_car.plate_character+' '+temp_car.plate_number,
                        car_province: form.car_plate_province,
                        customer_phone_number: form.customer_phone_number,
                      }),
                      (dialogEditCar = true)
                    "
                    v-if="isUpdate"
                  >
                    <div class="primary--text subtitle-1 text-decoration-underline">
                        กดแก้ไขเลขทะเบียน
                      </div>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="หมวดอักษร"
                    v-model="temp_car.plate_character"
                    outlined
                    dense
                    hide-details
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="เลขทะเบียน"
                    v-model="temp_car.plate_number"
                    outlined
                    dense
                    hide-details
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="temp_car.plate_province"
                    :items="list.provinces"
                    @change="
                      (val) => {
                        handleCarsInfo();
                      }
                    "
                    outlined
                    dense
                    label="จังหวัด"
                    :hide-details="!$v.form.car_plate_province.$error"
                    :error-messages="
                      $v.form.car_plate_province.$error ? $error_text : ''
                    "
                    disabled
                  ></v-select>
                </v-col>

                  <v-col cols="2" offset="2">
                    <v-btn
                      small block
                      color="primary white--text"
                      :outlined="form.car_type != 'CT1'"
                      @click="form.car_type = 'CT1'"
                      :disabled="isUpdate"
                      disabled
                    >
                      รย.1
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      small block
                      color="primary white--text"
                      :outlined="form.car_type != 'CT2'"
                      @click="form.car_type = 'CT2'"
                      :disabled="isUpdate"
                      disabled
                    >
                      รย.2
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      small block
                      color="primary white--text"
                      :outlined="form.car_type != 'CT3'"
                      @click="form.car_type = 'CT3'"
                      :disabled="isUpdate"
                      disabled
                    >
                      รย.3
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      small block
                      color="primary white--text"
                      :outlined="form.car_type != 'CT4'"
                      @click="form.car_type = 'CT4'"
                      :disabled="isUpdate"
                      disabled
                    >
                      รย.12
                    </v-btn>
                  </v-col>

                <v-col cols="12">
                  <v-select
                    item-value="value"
                    item-text="name"
                    v-model="form.car_type"
                    label="ประเภทรถ"
                    :items="list.car_type_dict"
                    :menu-props="{ maxHeight: '400' }"
                    outlined
                    dense
                    :hide-details="!$v.form.car_type.$error"
                    :error-messages="$v.form.car_type.$error ? $error_text : ''"
                    :disabled="true"
                    @change="isEditFilterExpressProduct = true"
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    name="car_weight"
                    v-model="form.car_weight"
                    label="น้ำหนักรถเปล่า (กก.)"
                    outlined
                    dense
                    hide-details
                    autocomplete="off"
                    :disabled="true"
                    @change="isEditFilterExpressProduct = true"
                    @keypress.native="carWeightNumber"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    name="engine_size"
                    v-model="form.engine_size"
                    label="เครื่องยนต์ (CC)"
                    outlined
                    dense
                    hide-details
                    autocomplete="off"
                    :disabled="true"
                    @change="isEditFilterExpressProduct = true"
                    @keypress.native="engineNumber"
                    @focus="$event.target.select()"
                  />
                </v-col>

                <v-col cols="6">
                  <DatePicker
                    label="วันจดทะเบียน"
                    :value="form.registration_date"
                    @onChange="(val) => (form.registration_date = val)"
                    hideDetails
                    :maxDate="current_date"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="6">
                  <DatePicker
                    label="วันที่ภาษีขาด"
                    :value="form.taxout_date"
                    @onChange="
                      (val) => (
                        (form.taxout_date = val),
                        (isEditFilterExpressProduct = true)
                      )
                    "
                    hideDetails
                    :maxDate="max_taxout_date"
                    :disabled="true"
                  />
                </v-col>

                <v-col cols="6">
                  <DatePicker
                    label="วันที่จะยื่นต่อ"
                    :value="form.tax_filling_date"
                    @onChange="
                      (val) => (
                        (form.tax_filling_date = val),
                        (isActiveTaxYearEnd = false),
                        (isEditFilterExpressProduct = true)
                      )
                    "
                    hideDetails
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="6" class="d-flex">
                  <div v-if="car_tax.total_tax_price[3]">
                    <v-btn
                      class="ml-5"
                      color="primary"
                      :outlined="!isActiveTaxYearInit"
                      @click="
                        (isActiveTaxYearInit = !isActiveTaxYearInit),
                          (isActiveTaxYearEnd = false),
                          (isEditFilterExpressProduct = true)
                      "
                    >
                      {{ car_tax.total_tax_price[1] + 543 }}
                    </v-btn>
                    <v-btn
                      class="ml-5"
                      color="primary"
                      :outlined="!isActiveTaxYearEnd"
                      @click="
                        (isActiveTaxYearInit = false),
                          (isActiveTaxYearEnd = !isActiveTaxYearEnd),
                          (isEditFilterExpressProduct = true)
                      "
                    >
                      {{ car_tax.total_tax_price[3] + 543 }}
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="5">
                  <v-radio-group
                    class="mt-0"
                    row
                    dense
                    v-model="form.is_original_document"
                    hide-details
                    :disabled="isUpdate"
                  >
                    <div class="mr-1">ชนิดเอกสาร :</div>
                    <v-radio
                      class="mr-1"
                      label="สำเนา"
                      :value="false"
                      disabled
                    ></v-radio>
                    <v-radio
                      class="mr-0"
                      label="เล่มจริง"
                      :value="true"
                      disabled
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <!-- <v-col cols="7" class="pt-5">
                  <span
                    class="layout-upload pointer primary--text"
                    @click="uploading ? '' : $refs.file.click()"
                    v-if="form.car_registration_url == ''"
                  >
                    <div v-if="uploading" class="text-upload">
                      <span>Uploading...</span>
                    </div>
                    คลิกอัพโหลดสำเนารถ (.jpg ไม่เกิน 500kb)
                    <input
                      v-show="false"
                      ref="file"
                      type="file"
                      class="file"
                      accept="image/jpeg"
                      @change="onFileChange"
                      :disabled="true"
                    />
                  </span>
                  <span v-if="form.car_registration_url != ''">
                    <span
                      class="layout-upload pointer primary--text"
                      @click="openUrl()"
                    >
                      ดูรูปสำเนารถ
                    </span>
                    /
                    <span
                      class="layout-upload pointer primary--text"
                      @click="deleteS3Obj(form.car_registration_url)"
                      disabled
                    >
                      ลบรูป
                    </span>
                  </span>
                </v-col> -->
                <v-col cols="12">
                  <!-- <v-radio-group
                    class="mt-0 mb-2"
                    row
                    dense
                    v-model="form.car_fuel"
                    hide-details
                    :disabled="isUpdate"
                  >
                    <div class="mr-1">เชื้อเพลิงหลัก  <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" small v-bind="attrs" v-on="on"
                          >mdi-information</v-icon
                          >
                        </template>
                      <span>เก็บไว้เป็นประวัติรถ และใช้แสดงในรายงานควันดำ</span>
                        </v-tooltip>
                    :</div>
                    <v-radio @click="isEditFilterExpressProduct = true;" label="ไม่ระบุ" :value="0"></v-radio>
                    <v-radio @click="isEditFilterExpressProduct = true;" label="ดีเซล" :value="1"></v-radio>
                    <v-radio @click="isEditFilterExpressProduct = true;" label="เบนซิน" :value="2"></v-radio>
                    <v-radio @click="isEditFilterExpressProduct = true;" label="ไฟฟ้า 100%" :value="3"></v-radio>
                    <v-radio @click="isEditFilterExpressProduct = true;" label="อื่นๆ" :value="4"></v-radio>
                  </v-radio-group> -->

                  <div class="mb-2">
                    เชื้อเพลิงเสริม (ถ้ามีต้องเลือกด้วย มีผลกับการคิดค่าภาษีรถ)
                  </div>
                  <v-btn
                    v-if="form.car_fuel != 3"
                    :color="form.extra_fuel == 1 ? '#E0E0E0' : 'white'"
                    class="mx-2 mb-5"
                    @click="
                      () => {
                        form.extra_fuel == 1 && !isUpdate
                          ? (form.extra_fuel = 0)
                          : (form.extra_fuel = 1),
                          (isEditFilterExpressProduct = true);
                      }
                    "
                    :disabled="isUpdate"
                    disabled
                  >
                    <span class="green--text">CNG</span>
                  </v-btn>

                  <v-btn
                    v-if="form.car_fuel != 3"
                    :color="form.extra_fuel == 2 ? '#E0E0E0' : 'white'"
                    class="mx-2 mb-5"
                    @click="
                      () => {
                        form.extra_fuel == 2 && !isUpdate
                          ? (form.extra_fuel = 0)
                          : (form.extra_fuel = 2),
                          (isEditFilterExpressProduct = true);
                      }
                    "
                    :disabled="isUpdate"
                    disabled
                  >
                    <span class="blue--text"> LPG</span>
                  </v-btn>

                  <v-btn
                    v-if="form.car_fuel != 3"
                    :color="form.extra_fuel == 3 ? '#E0E0E0' : 'white'"
                    class="mx-2 mb-5"
                    @click="
                      () => {
                        form.extra_fuel == 3 && !isUpdate
                          ? (form.extra_fuel = 0)
                          : (form.extra_fuel = 3),
                          (isEditFilterExpressProduct = true);
                      }
                    "
                    :disabled="isUpdate"
                    disabled
                  >
                    <span class="mr-1 cyan--text">CNG</span>
                    <span class="grey--text">100%</span>
                  </v-btn>

                  <v-btn
                    v-if="form.extra_fuel == 0"
                    :color="form.car_fuel == 3 ? '#E0E0E0' : 'white'"
                    class="mx-2 mb-5"
                    @click="
                      () => {
                        form.car_fuel == 3 && !isUpdate
                          ? (form.car_fuel = 0)
                          : (form.car_fuel = 3),
                          (isEditFilterExpressProduct = true);
                      }
                    "
                    :disabled="isUpdate"
                    disabled
                  >
                    <span class="mr-1 blue--text">ไฟฟ้า</span>
                    <span class="grey--text">100%</span>
                  </v-btn>

                  <v-checkbox
                    class="mt-0"
                    v-model="form.is_corporation_car"
                    label="จดนิติบุคคล (รย.1 ภาษีรถ x 2)"
                    :disabled="isUpdate"
                    @click="isEditFilterExpressProduct = true"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-text class="px-5">
              <v-row class="pa-0">
                <v-col cols="3">
                  <div class="text-h5 primary--text mb-5">
                    2. ข้อมูลลูกค้า
                  </div>
                </v-col>
                <v-col>
                  <span
                    class="layout-upload pointer primary--text"
                    @click="
                      (dialogEditDocumentData = {
                        document_id: form._id,
                        customer_name: form.customer_name,
                        customer_address: form.customer_address,
                        customer_is_delivery_address: form.customer_is_delivery_address,
                        customer_delivery_address: form.customer_delivery_address,
                        customer_phone_number: form.customer_phone_number,
                        customer_taxpayer_number: form.customer_taxpayer_number,
                        customer_company_branch: form.customer_company_branch,
                        customer_note: form.customer_note,
                        discounted_groups_id: form.discounted_groups_id,
                        call_alert: form.call_alert,
                        call_remind_renewal: form.call_remind_renewal,
                      }),
                      (dialogEditCustomer = true)
                    "
                    v-if="isUpdate"
                  >
                    <div class="ml-5 primary--text subtitle-1 text-decoration-underline">
                      กดแก้ไขชื่อลูกค้า
                    </div>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="customer"
                    :items="list.filter_customers"
                    :search-input.sync="customer_name_search"
                    return-object
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                    label="ชื่อลูกค้า"
                    autocomplete="off"
                    @change="
                      (val) => {
                        getCustomerById(val._id),
                          (isEditFilterExpressProduct = true);
                      }
                    "
                    auto-select-first
                    hide-details
                    :disabled="true"
                  >
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    name="customer_phone_number"
                    v-model="form.customer_phone_number"
                    :class="{'as-password': !toggleShowPhoneNumber}"
                    autocomplete="new-password"
                    label="เบอร์โทร"
                    outlined
                    dense
                    hide-details
                    :disabled="true"
                    id="customer_phone_number"
                    @keypress.native="isNumber"
                    @keydown="preventDelete"
                    @input="parsePhonePattern"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    name="customer_taxpayer_number"
                    v-model="form.customer_taxpayer_number"
                    label="เลขที่เสียภาษี"
                    outlined
                    dense
                    :disabled="true"
                    hide-details
                    type="tel"
                    maxlength="13"
                    @keypress.native="taxNumber"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    name="customer_address"
                    label="ที่อยู่"
                    outlined
                    dense
                    autocomplete="off"
                    @click="
                      (dialogAddressData = form.customer_address),
                        (dialogAddress = true),
                        (dialogAddressKey = 'customer_address')
                    "
                    :value="`${form.customer_address.address} ${form.customer_address.sub_district} ${form.customer_address.district} ${form.customer_address.province} ${form.customer_address.postal_code}`"
                    hide-details
                    :disabled="isUpdate"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    name="customer_company_branch"
                    v-model="form.customer_company_branch"
                    label="สาขา"
                    outlined
                    dense
                    hide-details
                    :disabled="true"
                  />
                </v-col>

                <v-col cols="6">
                  <v-textarea
                    name="customer_note"
                    v-model="form.customer_note"
                    label="หมายเหตุลูกค้า"
                    outlined
                    dense
                    rows="3"
                    hide-details
                    :disabled="true"
                  />
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="form.discounted_groups_id"
                    label="กลุ่มลูกค้า/ตัวแทน"
                    outlined
                    :items="list.discounted_groups"
                    dense
                    hide-details
                    item-text="name"
                    item-value="_id"
                    @change="
                      () => {
                        getThirdInsuranceProduct(form.product[0].sub_category_id),
                          getInsuranceProduct(form.product[1].sub_category_id),
                          getProduct(),
                          (isEditFilterExpressProduct = true);
                      }
                    "
                    :disabled="true"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="d-flex mb-0 pb-0">
                  <v-checkbox
                    class="mt-0"
                    v-model="form.is_delivery_address"
                    :disabled="true"
                  />
                  <v-text-field
                    name="customer_delivery_address"
                    label="ที่อยู่จัดส่ง"
                    outlined
                    dense
                    autocomplete="off"
                    @click="
                      (dialogAddressData = form.customer_delivery_address),
                        (dialogAddress = true),
                        (dialogAddressKey = 'customer_delivery_address')
                    "
                    :value="`${form.customer_delivery_address.address} ${form.customer_delivery_address.sub_district} ${form.customer_delivery_address.district} ${form.customer_delivery_address.province} ${form.customer_delivery_address.postal_code}`"
                    hide-details
                    :disabled="true"
                  />
                </v-col>

                <v-col cols="6">
                  <v-checkbox
                    class="mt-0"
                    v-model="form.call_alert"
                    label="โทรแจ้งเมื่องานเสร็จ"
                    :disabled="true"
                  />
                  <v-checkbox
                    class="mt-0"
                    v-model="form.call_remind_renewal"
                    label="โทรเตือนต่ออายุ"
                    :disabled="true"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col cols="12">
          <div class="text-h5 red--text text-center">
            * * * ลบสินค้าที่ไม่ได้คืนเงินออก แล้วใส่จำนวนเงินที่จะคืนเท่านั้น * *
            *
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12">
          <v-card outlined>
            <v-card-text class="px-5">
              <div class="text-h5 green--text mb-5">ข้อมูลสินค้า</div>

              <div class="mb-5" style="height: 40px">
                <!-- <span class="text-h6" style="color: white">.</span> !!!!! -->
                <span class="d-flex float-right">
                  <DatePicker
                    label="วันนัดรับเอกสาร"
                    :value="form.pick_up_date"
                    @onChange="(val) => (form.pick_up_date = val)"
                    :disabled="isUpdate"
                  />
                  <TimePicker
                    label="เวลารับ"
                    :value="form.pick_up_time"
                    @onChange="(val) => (form.pick_up_time = val)"
                    :disabled="isUpdate"
                  />
                  <v-checkbox
                    class="pt-0 mx-4"
                    label="แสดงวันนัดรับงาน"
                    :value="form.show_pickup"
                    @change="
                      (val) => (form.show_pickup = val == true ? val : false)
                    "
                    :disabled="isUpdate"
                  />
                </span>
              </div>

              <!-- <div>
                <v-checkbox class="mt-0" :value="product_all" @change="handleProductAllChecked" label="ทั้งหมด" :disabled="isUpdate" />
              </div>
              <div class="ml-10 d-flex">
                <v-checkbox class="mr-10 mt-0" value="inspect" v-model="product_selected" @change="handleProductChecked" label="ตรวจสภาพรถ" :disabled="isUpdate" />
                <v-checkbox class="mr-10 mt-0" value="third_insurand" v-model="product_selected" @change="handleProductChecked" label="พ.ร.บ." :disabled="isUpdate" />
                <v-checkbox class="mr-10 mt-0" value="tax" v-model="product_selected" @change="handleProductChecked" label="ค่าภาษี" :disabled="isUpdate" />
              </div> -->

              <v-divider />
              <v-card elevation="1" class="mt-5">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="light">
                      <tr>
                        <th class="text-center text-h6" style="width: 20%">
                          หมวดหมู่
                        </th>
                        <th class="text-center text-h6" style="width: 20%">
                          สินค้า
                        </th>
                        <th class="text-center text-h6" style="width: 130px">
                          วันเริ่มต้น
                        </th>
                        <th class="text-center text-h6" style="width: 100px">
                          เต็มปี
                        </th>
                        <th class="text-center text-h6" style="width: 150px">
                          วันสิ้นสุด
                        </th>
                        <th class="text-center text-h6">ราคา</th>
                        <th class="text-center text-h6" style="width: 100px">
                          ส่วนลด
                        </th>
                        <th class="text-center text-h6">ราคารวม</th>
                        <th class="text-center" style="width: 40px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="py-2">
                          <v-row>
                            <v-autocomplete
                              return-object
                              outlined
                              dense
                              label="เลือก พ.ร.บ."
                              autocomplete="off"
                              v-model="form.product[0].sub_category_id"
                              v-bind:items="
                                list_insurance.third_insurance_sub_category
                              "
                              item-text="name"
                              item-value="sub_category_id"
                              @change="
                                (val) =>
                                  getThirdInsuranceProduct(val.sub_category_id)
                              "
                              solo
                              filled
                              auto-select-first
                              hide-details
                              :disabled="isUpdate"
                            ></v-autocomplete>
                          </v-row>
                          <v-row>
                            <span
                              class="ml-3"
                              style="color: red"
                              v-if="
                                form.product[0].sub_category_id == '' &&
                                form.product[0].price != 0
                              "
                              >กรุณากรอกข้อมูล</span
                            >
                          </v-row>
                        </td>
                        <td class="px-1 py-3">
                          <v-autocomplete
                            return-object
                            outlined
                            dense
                            label="รหัส"
                            autocomplete="off"
                            v-bind:items="list_insurance.third_insurance_product"
                            :item-text="
                              (item) => item.sku_code + ' ' + item.sku_detail
                            "
                            @change="filterAndSetThirdInsuranceForm"
                            item-value="product_id"
                            solo
                            filled
                            auto-select-first
                            hide-details
                            :disabled="
                              isUpdate || !form.product[0].sub_category_id
                            "
                            v-model="form.product[0].product_id"
                          >
                            <template slot="item" slot-scope="{ item }">
                              {{ item.sku_code }} {{ item.sku_detail }}</template
                            >
                          </v-autocomplete>
                        </td>
                        <td class="px-1">
                          <DatePicker
                            label="วันเริ่มต้น"
                            :value="form.product[0].start_date"
                            @onChange="
                              (val) => (
                                changeIsFullYearByStartDate(val, is_full_year, 0),
                                !is_full_year ? cal_ins_not_full_year() : null
                              )
                            "
                            hideDetails
                            :disabled="isUpdate"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            class="mt-1 ml-5"
                            dense
                            v-model="is_full_year"
                            hide-details
                            @change="(val) => changeIsFullYear(val, 0)"
                            :disabled="isUpdate"
                          />
                        </td>
                        <td class="px-1">
                          <DatePicker
                            label="วันสิ้นสุด"
                            :value="form.product[0].end_date"
                            @onChange="
                              (val) => (
                                (form.product[0].end_date = val),
                                cal_ins_not_full_year(),
                                (is_full_year = false)
                              )
                            "
                            hideDetails
                            :disabled="isUpdate || is_full_year"
                          />
                        </td>
                        <td class="px-1">
                          <v-text-field
                            class="my-1"
                            outlined
                            dense
                            :value="
                              form.product[0].product_price | numeral('0,0.00')
                            "
                            hide-details
                            :disabled="isUpdate || !form.product[0].sub_category_id"
                            @change="
                              (value) =>
                                handleProductPrice(0, 'product_price', value)
                            "
                            @focus="$event.target.select()"
                          />
                        </td>

                        <td class="px-1">
                          <v-text-field
                            class="my-1"
                            outlined
                            dense
                            :value="form.product[0].discount | numeral('0,0.00')"
                            hide-details
                            :disabled="isUpdate || !form.product[0].sub_category_id"
                            @change="
                              (value) => handleProductPrice(0, 'discount', value)
                            "
                            @focus="$event.target.select()"
                          />
                        </td>
                        <td class="px-1">
                          <v-text-field
                            class="my-1"
                            outlined
                            dense
                            :value="form.product[0].price | numeral('0,0.00')"
                            hide-details
                            disabled
                          />
                        </td>
                        <td class="text-center px1">
                          <v-btn
                            class="my-1"
                            color="error"
                            fab
                            tile
                            small
                            elevation="0"
                            @click="deleteProduct(0)"
                            :disabled="isUpdate"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </td>
                      </tr>

                      <tr>
                        <td class="py-2">
                          <v-row>
                            <v-autocomplete
                              return-object
                              outlined
                              dense
                              label="เลือก ประกันภัย"
                              autocomplete="off"
                              v-bind:items="list_insurance.insurance_sub_category"
                              item-text="name"
                              @change="
                                (val) => getInsuranceProduct(val.sub_category_id)
                              "
                              solo
                              filled
                              auto-select-first
                              hide-details
                              :disabled="isUpdate"
                              v-model="form.product[1].sub_category_id"
                              item-value="sub_category_id"
                            ></v-autocomplete
                          ></v-row>
                          <v-row>
                            <span
                              class="ml-3"
                              style="color: red"
                              v-if="
                                form.product[1].sub_category_id == '' &&
                                form.product[1].price != 0
                              "
                              >กรุณากรอกข้อมูล</span
                            >
                          </v-row>
                        </td>
                        <td class="px-1 py-3">
                          <v-autocomplete
                            return-object
                            outlined
                            dense
                            label="รหัส"
                            autocomplete="off"
                            v-bind:items="list_insurance.insurance_product"
                            :item-text="(item) => item.sku_code"
                            solo
                            filled
                            auto-select-first
                            hide-details
                            :disabled="
                              isUpdate || !form.product[1].sub_category_id
                            "
                            @change="filterAndSetInsuranceForm"
                            item-value="product_id"
                            v-model="form.product[1].product_id"
                          >
                            <template slot="item" slot-scope="{ item }">
                              {{ item.sku_code }}</template
                            >
                          </v-autocomplete>
                        </td>
                        <td class="px-1">
                          <DatePicker
                            label="วันเริ่มต้น"
                            :value="form.product[1].start_date"
                            @onChange="
                              (val) =>
                                changeIsFullYearByStartDate(
                                  val,
                                  is_insurance_full_year,
                                  1
                                )
                            "
                            hideDetails
                            :disabled="isUpdate"
                          />
                        </td>
                        <td>
                          <v-checkbox
                            class="mt-1 ml-5"
                            dense
                            v-model="is_insurance_full_year"
                            hide-details
                            @change="(val) => changeIsFullYear(val, 1)"
                            :disabled="isUpdate"
                          />
                        </td>
                        <td class="px-1">
                          <DatePicker
                            label="วันสิ้นสุด"
                            :value="form.product[1].end_date"
                            @onChange="(val) => (form.product[1].end_date = val)"
                            hideDetails
                            :disabled="isUpdate || is_insurance_full_year"
                          />
                        </td>
                        <td class="px-1">
                          <v-text-field
                            class="my-1"
                            outlined
                            dense
                            :value="
                              form.product[1].product_price | numeral('0,0.00')
                            "
                            hide-details
                            :disabled="isUpdate || !form.product[1].sub_category_id"
                            @change="
                              (value) =>
                                handleProductPrice(1, 'product_price', value)
                            "
                            @focus="$event.target.select()"
                          />
                        </td>
                        <td class="px-1">
                          <v-text-field
                            class="my-1"
                            outlined
                            dense
                            :value="form.product[1].discount | numeral('0,0.00')"
                            hide-details
                            :disabled="isUpdate || !form.product[1].sub_category_id"
                            @change="
                              (value) => handleProductPrice(1, 'discount', value)
                            "
                            @focus="$event.target.select()"
                          />
                        </td>
                        <td class="px-1">
                          <v-text-field
                            class="my-1"
                            outlined
                            dense
                            :value="form.product[1].price | numeral('0,0.00')"
                            hide-details
                            disabled
                          />
                        </td>
                        <td class="text-center px1">
                          <v-btn
                            color="error"
                            class="my-1"
                            fab
                            tile
                            small
                            elevation="0"
                            @click="deleteProduct(1)"
                            :disabled="isUpdate"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-card elevation="0" class="mt-12">
                  <v-simple-table v-if="!isLoadingProduct">
                    <template v-slot:default>
                      <thead class="light">
                        <tr>
                          <th class="text-center text-h6" style="width: 40px">
                            #
                          </th>
                          <th class="text-center text-h6" style="width: 30%">
                            ชื่อสินค้า
                          </th>
                          <th class="text-center text-h6" style="width: 30%">
                            รายละเอียด
                          </th>
                          <th class="text-center text-h6">ราคา</th>
                          <th class="text-center text-h6" style="width: 100px">
                            ส่วนลด
                          </th>
                          <th class="text-center text-h6" style="width: 40px" v-if="list.setting.vat_registration || vat_price > 0">
                            Vat %
                          </th>
                          <th class="text-center text-h6">ราคารวม</th>
                          <th class="text-center" style="width: 40px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in form.product.slice(2)"
                          :key="index"
                        >
                          <td class="text-center px-1 my-3 table-style">
                            {{ index + 1 }}.
                          </td>
                          <td class="px-1 table-style">
                            <v-autocomplete
                              :value="item"
                              v-bind:items="list.product"
                              return-object
                              item-text="name"
                              outlined
                              dense
                              solo
                              filled
                              autocomplete="off"
                              @change="(val) => changeProduct(val, index + 2)"
                              auto-select-first
                              hide-details
                              :disabled="isUpdate"
                              class="my-3"
                            >
                            </v-autocomplete>
                          </td>
                          <td class="px-1">
                            <v-text-field
                              class="my-3"
                              :hide-details="true"
                              outlined
                              dense
                              v-model="item.detail"
                              :disabled="isUpdate"
                            />
                          </td>
                          <td class="px-1">
                            <v-text-field
                              class="my-3"
                              :hide-details="true"
                              outlined
                              dense
                              :value="item.product_price | numeral('0,0.00')"
                              :disabled="isUpdate"
                              @change="
                                (value) =>
                                  handleProductPrice(
                                    index + 2,
                                    'product_price',
                                    value
                                  )
                              "
                              @focus="$event.target.select()"
                            />
                          </td>
                          <td class="px-1">
                            <v-text-field
                              class="my-3"
                              :hide-details="true"
                              outlined
                              dense
                              :value="item.discount | numeral('0,0.00')"
                              :disabled="isUpdate"
                              @change="
                                (value) =>
                                  handleProductPrice(index + 2, 'discount', value)
                              "
                              @focus="$event.target.select()"
                            />
                          </td>
                          <td class="px-1 table-style" v-if="list.setting.vat_registration || vat_price > 0">
                            <v-select
                              class="ma-0 my-3"
                              outlined
                              dense
                              background-color="white"
                              :items="list.vat_dict"
                              v-model="item.vat"
                              item-value="value"
                              item-text="name"
                              hide-details
                              :disabled="isUpdate"
                              @change="
                                (value) =>
                                  handleProductPrice(index + 2, 'vat', value)
                              "
                            ></v-select>
                          </td>
                          <td class="px-1">
                            <v-text-field
                              class="my-3"
                              :hide-details="true"
                              outlined
                              dense
                              :value="item.price | numeral('0,0.00')"
                              disabled
                            />
                          </td>
                          <td class="text-center px-1 pt-3 pb-0 btn-table-style">
                            <v-btn
                              color="error"
                              fab
                              tile
                              small
                              elevation="0"
                              @click="deleteProduct(index + 2)"
                              :disabled="isUpdate"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-card-text>
                    <v-row justify="space-between" class="pt-4">
                      <v-col cols="4">
                        <!-- <v-btn
                          color="primary"
                          outlined
                          @click="addProduct"
                          :disabled="isUpdate"
                        >
                          + เพิ่มสินค้า
                        </v-btn> -->
                        <v-textarea
                          class="mt-10"
                          v-model="form.note"
                          label="หมายเหตุท้ายใบลดหนี้"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="6" md="5" lg="4" xl="3" class="pr-7 fz-3">
                        <div class="mb-3">
                          <span>มูลค่าตามเอกสารเดิม</span>
                          <span class="float-right">
                            {{ form.original_document_price | numeral('0,0.00') }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <span>มูลค่าที่ถูกต้อง</span>
                          <span class="float-right">
                            {{ differrence_price | numeral('0,0.00') }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <span>ผลต่าง</span>
                          <span class="float-right">
                            {{ total_price | numeral('0,0.00') }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <span>รวมเป็นเงิน</span>
                          <span class="float-right">
                            {{ total_price | numeral('0,0.00') }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <span>ส่วนลดรวม</span>
                          <span class="float-right">
                            {{ total_discount | numeral('0,0.00') }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <span>ราคาหลังหักส่วนลด</span>
                          <span class="float-right">
                            {{
                              (total_price - total_discount) | numeral('0,0.00')
                            }}
                          </span>
                        </div>
                        <div class="mb-3" v-if="list.setting.vat_registration || vat_price > 0">
                          <span>ภาษีมูลค่าเพิ่ม 7%</span>
                          <span class="float-right">
                            {{ vat_price | numeral('0,0.00') }}
                          </span>
                        </div>
                        <div class="mb-3">
                          <span>จำนวนเงินรวมทั้งสิ้น</span>
                          <span class="float-right">
                            {{ net_price | numeral('0,0.00') }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>

              <div
                v-if="
                  this.$route.params.id &&
                  form.payment.type != 0 &&
                  form.status != 0
                "
              >
                <div class="green--text fz-7 mt-10">บันทึกการชำระเงิน</div>
                <v-card elevation="1" class="mt-5">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead class="green">
                        <tr>
                          <th class="text-center white--text">วันที่ชำระ</th>
                          <th class="text-center white--text">ผู้คืนเงิน</th>
                          <th class="text-center white--text">ช่องทาง</th>
                          <th class="text-center white--text">รายละเอียด</th>
                          <th class="text-center white--text">ยอดรับชำระ</th>
                          <th class="text-center white--text">หมายเหตุ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">
                            {{ form.payment.date | moment($formatDate) }}
                            {{ form.payment.date | moment($formatTime) }}
                          </td>
                          <td class="text-center">
                            {{ form.payment.cashier_name }}
                          </td>
                          <td class="text-center">
                            {{ form.payment.type == 1 ? 'เงินสด' : '' }}
                            {{ form.payment.type == 2 ? 'โอนเงิน' : '' }}
                            {{ form.payment.type == 3 ? 'บัตรเครดิต' : '' }}
                          </td>
                          <td class="text-center">
                            {{ form.payment.reference }}
                          </td>
                          <td class="text-center">
                            {{ form.payment.amount | numeral('0,0.00') }}
                          </td>
                          <td class="text-center">{{ form.payment.note }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pb-10">
        <v-col class="text-right">
          <v-btn class="ml-5" small color="grey" outlined @click="close()"
            >ปิดหน้าต่าง</v-btn
          >
          <v-btn
            class="ml-5"
            color="primary white--text"
            small
            @click="save()"
            :disabled="loading"
          >
            บันทึกข้อมูล
          </v-btn>
          <PrintDocument
            :key="printKey"
            v-if="form.number && list.setting.receipt_printing_format == 0"
            :docTypeId="docTypeId"
            :type="docType"
            :data="dataPrint.main_bill"
            :data2="dataPrint.split_bill"
            ref="printDocument"
            :disabled="!form.number"
            :taxPenalty="tax_penalty"
            :totalTax="total_tax"
          >
            <v-btn
              class="ml-5"
              small
              color="primary"
              outlined
              :disabled="!form.number"
              :loading="loading"
            >
              พิมพ์ <v-icon right>mdi-printer</v-icon>
            </v-btn>
          </PrintDocument>
          <PrintDocument9inc
            :key="printKey"
            v-if="form.number && list.setting.receipt_printing_format == 2"
            :docTypeId="docTypeId"
            :type="docType"
            :data="dataPrint.main_bill"
            :data2="dataPrint.split_bill"
            ref="printDocument"
            :disabled="!form.number"
            :taxPenalty="tax_penalty"
            :totalTax="total_tax"
          >
            <v-btn
              class="ml-5"
              small
              color="primary"
              outlined
              :disabled="!form.number"
              :loading="loading"
            >
              พิมพ์ <v-icon right>mdi-printer</v-icon>
            </v-btn>
          </PrintDocument9inc>
          <PrintDocument80mm
            :key="printKey"
            v-if="form.number && list.setting.receipt_printing_format == 1"
            :docTypeId="docTypeId"
            :type="docType"
            :data="dataPrint.main_bill"
            :data2="dataPrint.split_bill"
            ref="printDocument"
            :disabled="!form.number"
          >
            <v-btn
              class="ml-5"
              small
              color="primary"
              outlined
              :disabled="!form.number"
              :loading="loading"
            >
              พิมพ์ <v-icon right>mdi-printer</v-icon>
            </v-btn>
          </PrintDocument80mm>

          <PrintDocumentFull
              :key="printKey"
              v-if="form.number && list.setting.receipt_printing_format == 3"
              :docTypeId="docTypeId"
              :type="docType"
              :data="dataPrint.main_bill"
              :data2="dataPrint.split_bill"
              ref="printDocument"
              :disabled="!form.number"
            >
              <v-btn
                class="ml-5"
                small
                color="primary"
                outlined
                :disabled="!form.number"
              >
                พิมพ์ <v-icon right>mdi-printer</v-icon>
              </v-btn>
            </PrintDocumentFull>

          <v-btn
            class="ml-5"
            small
            color="error"
            outlined
            @click="pay"
            :disabled="form.status != 0 || onCreate || loading"
            :loading="loading"
          >
            คืนเงิน
          </v-btn>
        </v-col>
      </v-row>

      <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
        <AddressDetail
          :item="dialogAddressData"
          @onClose="dialogAddress = false"
          @onSubmit="onSubmitAddress"
        />
      </v-dialog>

      <v-dialog persistent scrollable max-width="1200" v-model="dialogEditCar">
        <DocumentEditCar
          :item="dialogEditDocumentData"
          :provinces="list.provinces"
          @onClose="dialogEditCar = false"
          @onSubmit="onSubmitEditDocument"
        />
      </v-dialog>

      <v-dialog persistent scrollable max-width="1200" v-model="dialogEditCustomer">
        <DocumentEditCustomer
          :item="dialogEditDocumentData"
          :discountedGroups="list.discounted_groups"
          @onClose="dialogEditCustomer = false"
          @onSubmit="onSubmitEditDocument"
        />
      </v-dialog>

      <v-dialog persistent scrollable max-width="800" v-model="dialogAlert">
        <v-card class="py-5">
          <v-card-text class="popup-background">
            <v-row justify="center">
              <v-col cols="12" class="text-center">
                <div class="text-h5 mt-5" v-for="(msg, i) in list.alert" :key="i">
                  {{ msg }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="py-5">
            <v-spacer />
            <slot></slot>
            <v-btn
              outlined
              class="px-5 mr-10"
              color="grey"
              @click="dialogAlert = false"
            >
              ปิด
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import PrintDocument9inc from '@/components/PrintDocument/printDocument9inc.vue';
import PrintDocument80mm from '@/components/PrintDocument/printDocument80mm.vue';
import PrintDocumentFull from '@/components/PrintDocument/printDocumentFull.vue';
import AddressDetail from '@/components/Address/AddressDetail.vue';
import DocumentEditCar from '@/components/DocumentEditCar.vue';
import DocumentEditCustomer from '@/components/DocumentEditCustomer.vue';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import S3 from 'aws-s3';
import { required, numeric, minValue } from 'vuelidate/lib/validators';

import * as moment from 'moment';

export default {
  components: {
    PrintDocument,
    PrintDocument9inc,
    PrintDocument80mm,
    PrintDocumentFull,
    AddressDetail,
    DocumentEditCar,
    DocumentEditCustomer,
    DatePicker,
    TimePicker,
  },
  data: () => ({
    detailCar: false,
    detailCustomer: false,
    // aws s3 file upload config
    file: null,
    uploading: false,
    aws_access_key_id: 'AKIA2ETG3H3HEVIJOFU3',
    aws_secret_access_key: '---',
    aws_default_region: 'ap-southeast-1',
    aws_bucket: 'permtang',
    dirName: 'car_registration_book',
    aws_url: 'https://permtang.s3-ap-southeast-1.amazonaws.com',

    selectStart: -1,
    current_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
    max_taxout_date: moment().add(2, 'years').format('YYYY-MM-DDTHH:mm:ss'),
    customer_name_search: '',

    docTypeId: 3,
    docType: 'ใบลดหนี้',
    docPath: 'debit',
    dataPrint: {
      main_bill: null,
      split_bill: null,
    },
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAlert: false,
    dialogAddressData: {
      address: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: '',
    },
    dialogEditCar: false,
    dialogEditCustomer: false,
    dialogEditDocumentData: {
      document_id: '',
      car_plate: '',
      car_province: '',
      customer_address: {
        address: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: '',
      },
      customer_is_delivery_address: false,
      customer_delivery_address: {
        address: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: '',
      },
      customer_phone_number: '',
      customer_taxpayer_number: '',
      customer_company_branch: '',
      discounted_groups_id: '',
      call_alert: false,
      call_remind_renewal: false,
    },
    menu: {
      due_date: false,
    },
    is_full_year: false,
    is_insurance_full_year: false,
    form: {
      approval_id: '',
      approval: '',
      number: '',
      status: 0,
      reference_document: '',
      customer_name: '',
      customer_phone_number: '',
      customer_address: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: '',
      },
      customer_taxpayer_number: '',
      customer_company_branch: '',
      is_delivery_address: false,
      customer_delivery_address: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: '',
      },
      customer_group: '',
      call_alert: false,
      call_remind_renewal: false,
      customer_note: '',
      car_plate_character: '',
      car_plate_number: '',
      car_plate_province: '',
      car_type: 'CT1',
      registration_date: '',
      tax_filling_date: moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      is_corporation_car: false,
      car_registration_url: '',
      extra_fuel: 0,
      car_fuel: 0,
      car_weight: '',
      engine_size: '',
      is_original_document: false,
      taxout_date: '',
      branch_id: '',
      document_type: 0,
      customer_branch_office: '',
      date_of_issue: '',
      due_date: '',
      project_name: '',
      pick_up_date: '',
      pick_up_time: '',
      show_pickup: false,
      tax_year_init: '',
      tax_year_ended: '',
      temp_product: [],
      product: [
        {
          broker: '',
          category_id: 'PC1',
          discount: 0,
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sku_code: '',
          sku_detail: '',
          sub_category_id: '',
          sub_category_name: '',
          vat: 0,
          vat_price: 0,
          start_date: moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_date: moment()
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          product_price: 0,
        },
        {
          broker: '',
          category_id: 'PC2',
          discount: 0,
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sku_code: '',
          sub_category_id: '',
          sub_category_name: '',
          type: 0,
          vat: 0,
          vat_price: 0,
          start_date: moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_date: moment()
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          product_price: 0,
        },
        {
          category_id: '',
          discount: 0,
          message_on_select: '',
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sub_category_id: '',
          sub_category_name: '',
          vat: 0,
          vat_price: 0,
          product_price: 0,
        },
      ],
      payment: {
        type: 0,
        reference: '',
        note: '',
        date: moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss'),
        amount: 0,
      },
      original_document_price: 0,
      total_price: 0,
      total_discount: 0,
      net_price: 0,
      notation: '',
      note: '',
      discounted_groups_id: '',
    },
    thisBranch: {},
    list: {
      approval: [],
      provinces: [],
      product: [],
      car_type_dict: [
        { value: 'CT1', name: '(รย.1) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT2', name: '(รย.2) รถยนต์นั่งส่วนบุคคลเกิน 7 คน' },
        { value: 'CT3', name: '(รย.3) รถยนต์บรรทุกส่วนบุคคล' },
        { value: 'CT4', name: '(รย.12) รถจักรยานยนต์ส่วนบุคคล' },
        { value: 'CT5', name: '(รย.13) รถแทรกเตอร์ที่ใช้ในการเกษตร' },
        { value: 'CT14', name: '(รย.15) รถใช้งานเกษตรกรรม' },
        {
          value: 'CT6',
          name: '(ขส.10) รถโดยสารประจำทาง (เลขทะเบียนข้างหน้าเป็น 10-19)',
        },
        {
          value: 'CT7',
          name: '(ขส.30) รถโดยสารไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 30-39)',
        },
        {
          value: 'CT8',
          name: '(ขส.40) รถโดยสารส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 40-49)',
        },
        {
          value: 'CT9',
          name: '(ขส.70) รถบรรทุกไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 70-79)',
        },
        {
          value: 'CT10',
          name: '(ขส.80) รถบรรทุกส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 50-59, 80-99)',
        },
        { value: 'CT11', name: '(รย.1EV) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT12', name: 'อื่นๆ' },
      ],
      vat_dict: [
        { value: 0, name: '0%' },
        { value: 1, name: '7%' },
      ],
      setting: null,
      discounted_groups: [],
      cars: [],
      filter_customers: [],
      alert: [],
      expressProduct: {
        inspect_product: null,
        tax_product: null,
        third_insurand_product: null,
        extension_tax_fee_product: null,
        motorcycle_3rdinsurance: null,
      },
    },
    loading: true,
    loadingPrint: true,
    approvals: null,
    car: null,
    temp_car: {
      _id: '',
      plate_character: '',
      plate_number: '',
      plate_province: '',
    },
    customer: {
      _id: '',
      name: '',
    },
    temp_customer: {
      _id: '',
      name: '',
    },
    isEdit: true,
    product_all: false,
    product_selected: [],
    isEditFilterExpressProduct: false,
    isUpdate: false,
    onCreate: false,
    car_tax: {
      net_tax_price: 0,
      total_tax_price: [0, 0, 0, 0],
      tax_price: [0, 0, 0, 0, 0, 0],
      tax_by_year: [0, 0, 0, 0],
      popup_massage: ['', '', '', '', ''],
      late_age: 0,
      car_age: 0,
      text_alert: '',
      tax_year_init: moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      tax_year_ended: null,
    },
    isActiveTaxYearInit: false,
    isActiveTaxYearEnd: false,
    tax_penalty: 0,
    total_tax: 0,
    isLoadingProduct: false,
    list_insurance: {
      insurance_sub_category: [],
      third_insurance_sub_category: [],
      third_insurance_product: [],
      insurance_product: [],
    },
    permission: {},
    showPhoneNumber: false,
    transTimeout: null,
    toggleShowPhoneNumber: false,
    editMode: null,
    deleteOnce: false,
    printKey: 1
  }),
  validations: {
    form: {
      car_plate_character: { required },
      car_plate_number: { required, numeric, minValue: minValue(0) },
      car_plate_province: { required },
      car_type: { required },
    },
  },
  async created() {
    if (!this.$route.params.id && this.onCreate)
      this.$router.push({ name: `document-debit` });
    this.loading = true;
    await this.getUserGroup();
    this.form.branch_id = this.$store.state.selected_branch._id;
    await this.getSetting();
    await this.getProvinces();
    await this.getApproval();
    await this.getCustomers();
    await this.getProduct();
    await this.getDiscountedGroups();
    
    this.isUpdate = this.$route.params.id ? true : false;
    this.editMode = this.$route.params.id ? true : false;
    if(!this.editMode){
      this.toggleShowPhoneNumber = true
    }
    if (this.isUpdate) {
      await this.getExpressProduct();
      await this.getData();
      if (this.form.status == 0) {
        this.getApprovalById(this.$store.state.profile._id);

        this.isUpdate = false;
      }
      if (this.form.status == 1 || this.form.status == 2) {
        this.isEdit = false;
      }
    } else {
      await this.getApprovalById(this.$store.state.profile._id);
      await this.getDocumentNote();
      this.form.date_of_issue = moment().format('YYYY-MM-DDTHH:mm:ss');
      this.form.due_date = moment()
        .add(30, 'days')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    await this.getThirdInsuranceSubCategory();
    await this.getInsuranceSubCategory();

    this.loading = false;

    if (
      this.$route.query.print == 'true' ||
      this.$route.query.download == 'true' ||
      this.$route.query.print == true ||
      this.$route.query.download == true
    ) {
      this.$refs.printDocument.print();
      this.$route.query.print = false;
    } else if (
      this.$route.query.pay == 'true' ||
      this.$route.query.pay == true
    ) {
      this.$router.push({
        name: 'document-debit-pay',
        params: { id: this.$route.params.id },
      });
    }
  },

  methods: {
    async getThirdInsuranceSubCategory() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(
          `${this.$baseUrl}/document/get_third_insurance_sub_category`,
          body
        )
        .then(async (res) => {
          this.list_insurance.third_insurance_sub_category = res.result;
        });
      if (this.form.product[0] && this.form.product[0].sub_category_id) {
        if (typeof this.form.product[0].sub_category_id === 'object') {
          this.form.product[0].sub_category_id =
            this.form.product[0].sub_category_id.sub_category_id;
          await this.getThirdInsuranceProduct(
            this.form.product[0].sub_category_id
          );
        } else {
          await this.getThirdInsuranceProduct(
            this.form.product[0].sub_category_id
          );
        }
      }
    },
    async getInsuranceSubCategory() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/get_insurance_sub_category`, body)
        .then(async (res) => {
          this.list_insurance.insurance_sub_category = res.result;
        });

      if (this.form.product[1] && this.form.product[1].sub_category_id) {
        await this.getInsuranceProduct(this.form.product[1].sub_category_id);
      }
    },
    async filterAndSetInsuranceForm(val) {
      let sub_category_id = this.form.product[1].sub_category_id;
      let product_id = val.product_id;
      let newForm = {};
      let sub_cat_detail = this.list_insurance.insurance_sub_category.filter(
        (sub_cat) => sub_cat.sub_category_id == sub_category_id
      );
      let product_detail = this.list_insurance.insurance_product.filter(
        (product) => product.product_id == product_id
      );
      if (sub_cat_detail.length > 0) {
        newForm = { ...newForm, ...sub_cat_detail[0] };
      }
      if (product_detail.length > 0) {
        newForm = { ...newForm, ...product_detail[0] };
      }
      newForm = { ...this.form.product[1], ...newForm };
      this.changeInsurance(newForm);
    },
    async filterAndSetThirdInsuranceForm(val) {
      let sub_category_id = this.form.product[0].sub_category_id;
      let product_id = val.product_id;
      let newForm = {};
      let sub_cat_detail =
        this.list_insurance.third_insurance_sub_category.filter(
          (sub_cat) => sub_cat.sub_category_id == sub_category_id
        );
      let product_detail = this.list_insurance.third_insurance_product.filter(
        (product) => product.product_id == product_id
      );
      if (sub_cat_detail.length > 0) {
        newForm = { ...newForm, ...sub_cat_detail[0] };
      }
      if (product_detail.length > 0) {
        newForm = { ...newForm, ...product_detail[0] };
      }
      newForm = { ...this.form.product[0], ...newForm };
      this.changeThirdInsurance(newForm);
    },
    async getThirdInsuranceProduct(sub_category_id) {
      if (sub_category_id != '') {
        this.list_insurance.third_insurance_product = [];
        let filter = {
          sub_category_id: sub_category_id,
          car_type: this.form.car_type,
          discounted_groups_id: this.form.discounted_groups_id,
        };
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/document/get_third_insurance_product`, body)
          .then(async (res) => {
            this.list_insurance.third_insurance_product = res.result;
           if (this.list_insurance.third_insurance_product.length == 0) {
              this.form.product[0].product_id = '';
              this.form.product[0].product_price = 0;
              this.form.product[0].price = 0;
              this.form.product[0].discount = 0;
              this.form.product[0].start_date = this.form.product[0].start_date;
              this.form.product[0].end_date = this.form.product[0].end_date;
              this.$alertServerError({
                title: 'Error',
                html: 'ไม่มีสินค้าที่เปิดใช้งาน <br/>กรุณาเปิดใช้งานสินค้า พ.ร.บ ให้เข้ากับประเภทรถ',
                showConfirmButton: true,
                timer: false,
              });
            } else {
              if (this.form.product[0].product_id == '') {
                this.form.product[0].product_id =
                  this.list_insurance.third_insurance_product[0].product_id;
                this.setThirdInsuranceForm(
                  this.list_insurance.third_insurance_product[0]
                );
              }
            }
          });
      }
    },
    async getInsuranceProduct(sub_category_id) {
      if (sub_category_id != '') {
        if (typeof sub_category_id==='object'){
          sub_category_id = sub_category_id.sub_category_id
        }
        this.list_insurance.insurance_product = [];
        let filter = {
          sub_category_id: sub_category_id,
          car_type: this.form.car_type,
          discounted_groups_id: this.form.discounted_groups_id,
        };
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/document/get_insurance_product`, body)
          .then(async (res) => {
            this.list_insurance.insurance_product = res.result;
            if(res.result.length>0 && this.form.product[1].product_id=='') this.changeInsurance(res.result[0]);
            if(!res.result.length){
              this.$alertServerError({
                title: 'Error',
                html: 'ไม่มีสินค้าที่เปิดใช้งาน <br/>กรุณาเปิดใช้งานสินค้าประกันภัย',
                showConfirmButton: true,
                timer: false,
              });
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    engineNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.engine_size.length >= 4 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    carNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.temp_car.plate_number.length >= 4 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    taxNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.customer_taxpayer_number.length >= 13 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.customer_phone_number.length >= 11 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    preventDelete(evt) {
      let arrowKey = [37, 38, 39, 40];
      let selectStart = evt.target.selectionStart;
      let selectEnd = evt.target.selectionEnd;
      if(this.editMode && !this.showPhoneNumber && !this.deleteOnce){
        this.deleteOnce = true
        this.form.customer_phone_number = ""
        return
      }
      if (evt.keyCode === 8 || evt.keyCode === 46) {
        if (evt.target.value[selectStart - 1] == '-') {
          setTimeout(
            function () {
              this.form.customer_phone_number = this.parsePhonePattern(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
              evt.target.value = this.parsePhonePattern(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
            }.bind(this),
            0
          );
        }
      } else {
        if (evt.target.value.length >= 12 && !arrowKey.includes(evt.keyCode)) {
          evt.preventDefault();
        } else {
          this.selectStart = -1;
        }
      }
    },
    parsePhonePattern(value, returnValue = false) {
      let position = value
        .substr(0, 12)
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,7})/);
      let phoneNumber = '';
      phoneNumber = position[1];

      if (position[1].length >= 3) {
        phoneNumber += '-';
      }

      phoneNumber += position[2];

      if (returnValue) {
        return phoneNumber;
      } else {
        this.form.customer_phone_number = phoneNumber;
        if (this.selectStart > -1) {
          let input = document.getElementById('customer_phone_number');
          let selectionStart = this.selectStart;
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart);
          }, 0);
        } else if (window.event.target.selectionStart < value.length) {
          let input = document.getElementById('customer_phone_number');
          let selectionStart = window.event.target.selectionStart;
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart, 'forward');
          }, 0);
        }
      }
    },
    productFormat() {
      let temp = [];
      let hasPC1 = false;
      let hasPC2 = false;
      let notGen = true;
      this.form.product.forEach((item, i) => {
        if (item.category_id == 'PC1') hasPC1 = true;
        if (item.category_id == 'PC2') hasPC2 = true;
      });
      if (!hasPC1) {
        temp.splice(0, 0, {
          broker: '',
          category_id: 'PC1',
          discount: 0,
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sku_code: '',
          sku_detail: '',
          sub_category_id: '',
          sub_category_name: '',
          vat: 0,
          vat_price: 0,
          start_date: moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_date: moment()
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          product_price: 0,
        });
      }
      if (!hasPC2) {
        temp.splice(1, 0, {
          broker: '',
          category_id: 'PC2',
          discount: 0,
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sku_code: '',
          sku_detail: '',
          sub_category_id: '',
          sub_category_name: '',
          type: 0,
          vat: 0,
          vat_price: 0,
          start_date: moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_date: moment()
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          product_price: 0,
        });
      }
      this.product_selected = [];
      this.form.product.forEach((item, i) => {
        if (item.category_id == 'PC1' && hasPC1) {
          temp.splice(0, 0, item);
          this.is_full_year =
            moment(item.start_date)
              .add(365, 'days')
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DDTHH:mm:ss') ==
            moment(item.end_date)
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DDTHH:mm:ss')
              ? true
              : false;
          this.list.expressProduct.third_insurand_product = item;
          this.product_selected.push('third_insurand');
        } else if (item.category_id == 'PC2' && hasPC2) {
          temp.splice(1, 0, item);
          this.is_insurance_full_year =
            moment(item.start_date)
              .add(365, 'days')
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DDTHH:mm:ss') ==
            moment(item.end_date)
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DDTHH:mm:ss')
              ? true
              : false;
        } else if (item.category_id != '' && item.name != '') {
          notGen = false;
          temp.push(item);
        }
        if (item.tag == 'tax_include_fines') {
          this.list.expressProduct.tax_product = item;
          this.product_selected.push('tax');
          // this.product_selected
        }
        if (Object.keys(item).includes('tag')) {
          if (item.tag.indexOf('inspect') !== -1) {
            this.list.expressProduct.inspect_product = item;
            this.product_selected.push('inspect');
          }
        }
      });

      // this.product_selected = ["inspect", , "third_insurand"];
      if (notGen) {
        temp.push({
          category_id: '',
          discount: 0,
          message_on_select: '',
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sub_category_id: '',
          sub_category_name: '',
          vat: 0,
          vat_price: 0,
          product_price: 0,
        });
      }
      this.form.product = temp;
    },
    async getData(id = this.$route.params.id) {
      let body = {
        token: this.$jwt.sign({ document_id: id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...this.form, ...res.result };
          this.detailCustomer = res.result.detailCustomer;
          this.detailCar = res.result.detailCar;
          this.customer = {
            _id: this.form.customer_id,
            name: this.form.customer_name,
          };
          this.form.is_delivery_address =
            res.result.customer_is_delivery_address;
          this.temp_product = JSON.parse(JSON.stringify(this.form.product));
          if (this.form.document_type == 2) {
            this.onCreate = true;
            this.form.status = 0;
            this.form.approval = this.$store.state.profile.name;
            this.form.date_of_issue = moment().format('YYYY-MM-DDTHH:mm:ss');
            this.form.due_date = moment()
              .add(30, 'days')
              .format('YYYY-MM-DDTHH:mm:ss');
            await this.getDocumentNote();
            this.$router
              .replace({ name: 'document-debit-create' })
              .catch(() => {});
          } else {
            this.onCreate = false;
            await this.getDataPrint();
          }
          if (this.form.product.length === 0) {
            this.form.product = [
              {
                broker: '',
                category_id: 'PC1',
                discount: 0,
                name: '',
                price: 0,
                product_id: '',
                product_type: '',
                sku_code: '',
                sku_detail: '',
                sub_category_id: '',
                sub_category_name: '',
                vat: 0,
                vat_price: 0,
                start_date: moment()
                  .set({ hour: 0, minute: 0, second: 0 })
                  .format('YYYY-MM-DDTHH:mm:ss'),
                end_date: moment()
                  .add(365, 'days')
                  .set({ hour: 0, minute: 0, second: 0 })
                  .format('YYYY-MM-DDTHH:mm:ss'),
                product_price: 0,
              },
              {
                broker: '',
                category_id: 'PC2',
                discount: 0,
                name: '',
                price: 0,
                product_id: '',
                product_type: '',
                sku_code: '',
                sub_category_id: '',
                sub_category_name: '',
                type: 0,
                vat: 0,
                vat_price: 0,
                start_date: moment()
                  .set({ hour: 0, minute: 0, second: 0 })
                  .format('YYYY-MM-DDTHH:mm:ss'),
                end_date: moment()
                  .add(365, 'days')
                  .set({ hour: 0, minute: 0, second: 0 })
                  .format('YYYY-MM-DDTHH:mm:ss'),
                product_price: 0,
              },
              {
                category_id: '',
                discount: 0,
                message_on_select: '',
                name: '',
                price: 0,
                product_id: '',
                product_type: '',
                sub_category_id: '',
                sub_category_name: '',
                vat: 0,
                vat_price: 0,
                product_price: 0,
              },
            ];
          } else {
            this.productFormat();
          }
          if (this.form.status == 1 || this.form.status == 2)
            await this.getApprovalById(this.form.approval_id);
          await this.getCarById(this.form.car_id, false);
          await this.getCustomerById(this.form.customer_id);
        })
        .catch((err) => {
          console.error('err', err);
        });
    },
    changeIsFullYear(val, index) {
      if (val) {
        this.form.product[index].end_date = moment(
          this.form.product[index].start_date
        )
          .add(1, 'year')
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
      }
      if (index == 0) this.cal_ins_not_full_year();
    },
    async save(isClose = false) {
      // this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.valid = true;
      this.errors = {};
      let errors = [];
      this.loading = true;
      let isProductInvalid = false;
      this.form.product.forEach((item, i) => {
        if (item.product_id != '') isProductInvalid = true;
      });
      if (!isProductInvalid) {
        errors.push('กรุณาระบุสินค้าอย่างน้อย 1 รายการ');
        this.valid = false;
      }
      let isProductPrice = true;
      this.form.product.forEach((item, i) => {
        if (
          (item.product_price != 0 && item.product_id == '') ||
          (item.product_id == '' && item.sub_category_id != '')
        ) {
          isProductPrice = false;
        }
      });
      if (!isProductPrice && isProductInvalid) {
        errors.push('กรุณาระบุสินค้าและหมวดหมู่');
        this.valid = false;
      }

      // Error Popup Dialog
      if (errors && errors.length) {
        let htmlMsg = errors.join('<br/>');
        this.$alertServerError({
          title: 'Error',
          html: htmlMsg,
          showConfirmButton: true,
          timer: false,
        });
        this.loading = false;
        this.$v.form.$reset();
      }
      if (this.valid) {
        this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
          async (result) => {
            if (result.isConfirmed) {
              this.form.document_type = 3;
              this.form.total_price = this.differrence_price;
              this.form.total_discount = this.total_discount;
              this.form.vat_price = this.vat_price;
              this.form.net_price = this.net_price;
              this.form.reference_document = this.onCreate
                ? this.form.number
                : this.form.reference_document;

              this.form.tax_year_init = this.car_tax.tax_year_init || '';
              this.form.tax_year_ended = this.isActiveTaxYearEnd
                ? this.car_tax.tax_year_ended || ''
                : '';
              this.form.is_one_year_extension_tax = this.isActiveTaxYearInit;

              let subPath = 'add_document';
              if (!this.onCreate) {
                subPath = 'edit_document';
                this.form = { document_id: this.form._id, ...this.form };
                // delete this.form._id;
              }
              let sendForm = {
                ...this.form,
                customer_phone_number:
                  this.form.customer_phone_number.replaceAll('-', ''),
              };
              let body = {
                token: this.$jwt.sign(sendForm, this.$privateKey, {
                  noTimestamp: true,
                }),
              };
              await this.$axios
                .post(`${this.$baseUrl}/document/${subPath}`, body)
                .then(async (res) => {
                  this.product_selected = [];
                  this.$alertSuccess({ title: res.status });
                  this.form.status = 0;
                  await this.getData(res.result);
                  //await this.getDataPrint(res.result);
                  if (!isClose) {
                    this.$router
                      .push({
                        name: 'document-debit-update',
                        params: { id: res.result },
                      })
                      .catch(() => {});
                  } else {
                    this.close();
                  }
                })
                .catch((err) => {
                  console.error('err', err);
                  this.$alertServerError({ title: err.error_message });
                });
              this.onCreate = false;
            }
            this.loading = false;
            this.$v.form.$reset();
          }
        );
      }
    },
    // async saveNoAlert(isClose = false) {
    //   // this.$v.form.$touch();
    //   if (this.$v.form.$invalid) return;
    //   this.valid = true;
    //   this.errors = {};
    //   let errors = [];
    //   this.loading = true;
    //   let isProductInvalid = false;
    //   this.form.product.forEach((item, i) => {
    //     if (item.product_id != '') isProductInvalid = true;
    //   });
    //   if (!isProductInvalid) {
    //     errors.push('กรุณาระบุสินค้าอย่างน้อย 1 รายการ');
    //     this.valid = false;
    //   }
    //   let isProductPrice = true;
    //   this.form.product.forEach((item, i) => {
    //     if (
    //       (item.product_price != 0 && item.product_id == '') ||
    //       (item.product_id == '' && item.sub_category_id != '')
    //     ) {
    //       isProductPrice = false;
    //     }
    //   });
    //   if (!isProductPrice && isProductInvalid) {
    //     errors.push('กรุณาระบุสินค้าและหมวดหมู่');
    //     this.valid = false;
    //   }

    //   // Error Popup Dialog
    //   if (errors && errors.length) {
    //     let htmlMsg = errors.join('<br/>');
    //     this.$alertServerError({
    //       title: 'Error',
    //       html: htmlMsg,
    //       showConfirmButton: true,
    //       timer: false,
    //     });
    //     this.loading = false;
    //     this.$v.form.$reset();
    //   }
    //   if (this.valid) {
    //     this.form.document_type = 3;
    //     this.form.total_price = this.differrence_price;
    //     this.form.total_discount = this.total_discount;
    //     this.form.vat_price = this.vat_price;
    //     this.form.net_price = this.net_price;
    //     this.form.reference_document = this.onCreate
    //       ? this.form.number
    //       : this.form.reference_document;

    //     this.form.tax_year_init = this.car_tax.tax_year_init || '';
    //     this.form.tax_year_ended = this.isActiveTaxYearEnd
    //       ? this.car_tax.tax_year_ended || ''
    //       : '';
    //     this.form.is_one_year_extension_tax = this.isActiveTaxYearInit;

    //     let subPath = 'add_document';
    //     if (!this.onCreate) {
    //       subPath = 'edit_document';
    //       this.form = { document_id: this.form._id, ...this.form };
    //       // delete this.form._id;
    //     }
    //     let sendForm = {
    //       ...this.form,
    //       customer_phone_number: this.form.customer_phone_number.replaceAll(
    //         '-',
    //         ''
    //       ),
    //     };
    //     let body = {
    //       token: this.$jwt.sign(sendForm, this.$privateKey, {
    //         noTimestamp: true,
    //       }),
    //     };
    //     await this.$axios
    //       .post(`${this.$baseUrl}/document/${subPath}`, body)
    //       .then(async (res) => {
    //         this.product_selected = [];
    //         this.form.status = 0;
    //         await this.getData(res.result);
    //         await this.getDataPrint(res.result);
    //       })
    //       .catch((err) => {
    //         console.error('err', err);
    //         this.$alertServerError({ title: err.error_message });
    //       });

    //     this.loading = false;
    //   }
    // },
    pay() {
      //this.saveNoAlert();
      setTimeout(() => {
        this.$router.push({
          name: 'document-debit-pay',
          params: { id: this.$route.params.id },
        });
      },);
    },
    close() {
      this.$router.push({ name: `document-${this.docPath}` });
    },
    async saveClose() {
      this.save(true);
    },
    onSubmitAddress(val) {
      this.form[this.dialogAddressKey] = val;
      this.dialogAddress = false;
    },
    onSubmitEditDocument(val) {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let sendForm = {
              ...this.dialogEditDocumentData,
              customer_phone_number:
                this.dialogEditDocumentData.customer_phone_number.replaceAll('-', ''),
            };
            let body = {
              token: this.$jwt.sign(sendForm, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/document/edit_data`, body)
              .then(async (res) => {
                if(res.status=='carExistDatabase'){
                  this.$alertServerError({ title: 'ข้อมูลซ้ำกับในฐานข้อมูล' });
                } else if(res.status=='success'){
                  this.$alertSuccess({ title: 'ส่งแก้ไขสำเร็จ' });
                  if(!this.list.setting.document_approvement){
                    let edit_data_approvement_body = {
                      token: this.$jwt.sign({ log_document_id: res.result, approvement:true }, this.$privateKey, {
                        noTimestamp: true,
                      }),
                    };
                    await this.$axios.post(`${this.$baseUrl}/document/edit_data_approvement`, edit_data_approvement_body)
                      .then(async (res) => {
                        this.getData();
                        this.getCustomers();
                      })
                  }
                }
              })
              .catch((err) => {
                console.error('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
      this.dialogEditCar = false;
      this.dialogEditCustomer = false;
    },
    async getDiscountedGroups() {
      let filter = {};
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/discounted_groups/list_group`, body)
        .then(async (res) => {
          this.list.discounted_groups = res.result;
          this.form.discounted_groups_id = res.result[0]._id;
        });
    },
    async getSetting() {
      let listFiltered = this.$store.state.list.branchs.filter((el) => {
          return el._id == this.$store.state.selected_branch._id;
      });
      this.list.setting = listFiltered[0].setting;
      this.thisBranch = listFiltered[0];
      await this.handlePickupDate();
      this.form.pick_up_time =
        this.form.pick_up_time == ''
          ? this.list.setting.document_pickup_time
          : this.form.pick_up_time;
      this.form.tax_filling_date =
        this.list.setting.tax_filling_date > this.form.tax_filling_date
          ? this.list.setting.tax_filling_date
          : this.form.tax_filling_date;
      let starting_province = [this.list.setting.starting_province];
      let provinces = await this.list.provinces.filter((value) => {
        return starting_province.indexOf(value) == -1;
      });
      this.list.provinces = starting_province.concat(provinces);
    },
    async handlePickupDate() {
      let document_pickup_date = parseInt(
        this.list.setting.document_pickup_date
      );
      let days = 0;
      let number = 0;
      let day = 0;
      while (day < document_pickup_date) {
        let day_number = await moment().add(days, 'days').day();
        days++;
        if (day_number == 0 || day_number == 6) {
          number++;
        } else {
          day++;
        }
      }
      let totalDays = document_pickup_date + number;
      this.form.pick_up_date = moment()
        .add(totalDays - 1, 'days')
        .format('YYYY-MM-DD');
    },
    async getApprovalById(approval_id) {
      this.form.approval_id = approval_id;
      const body = {
        token: this.$jwt.sign({ user_id: approval_id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };

      try {
        let data;
        if (approval_id == this.$store.state.profile._id) {
          data = this.$store.state.profile;
        } else {
           let res = await this.$axios.post(
          `${this.$baseUrl}/users/get_by_id`,
          body
        );
          data = res.result;
        };
       
        if (data) {
          this.approvals = data;
          this.form.approval_id = data._id;
          this.form.approval = data.name;
        };
      } catch (err) {
        console.error('err', err);
      }
    },
    async getCustomerById(customer_id) {
      this.form.customer_id = customer_id;
      if (this.form.customer_id == '') {
        this.customer = '';
        this.form.customer_name = this.customer_name_search;
        this.form.customer_address = {
          address: '',
          district: '',
          sub_district: '',
          province: '',
          postal_code: '',
        };
        this.form.customer_phone_number = this.parsePhonePattern('', true);
        this.form.customer_taxpayer_number = '';
        this.form.customer_company_branch = '';
        this.form.customer_note = '';
        this.form.customer_delivery_address = {
          address: '',
          district: '',
          sub_district: '',
          province: '',
          postal_code: '',
        };
        this.form.call_alert = false;
        this.form.call_remind_renewal = false;
        this.form.discounted_groups_id = this.list.discounted_groups[0]._id;
      } else if (this.form.customer_id != '') {
        let body = {
          token: this.$jwt.sign(
            { customer_id: customer_id },
            this.$privateKey,
            { noTimestamp: true }
          ),
        };
        await this.$axios
          .post(`${this.$baseUrl}/customer/get_by_id`, body)
          .then(async (res) => {
            let data = res.result;
            if (data) {
              this.customer = data;
              this.list.filter_customers.push({
                '_id': this.customer._id,
                'name': this.customer.name
              })
              this.customer_name_search = data.name;
              this.form.customer_name = data.name;
              this.form.customer_address = data.location;
              this.form.customer_phone_number = this.parsePhonePattern(
                data.phone_number,
                true
              );
              this.form.customer_taxpayer_number = data.taxpayer_number;
              this.form.customer_company_branch = data.company_branch;
              this.form.customer_note = data.note;
              this.form.customer_delivery_address = data.delivery_address;
              this.form.call_alert = data.call_alert;
              this.form.call_remind_renewal = data.call_remind_renewal;
              this.form.discounted_groups_id =
                data.customer_group != ''
                  ? data.customer_group
                  : this.list.discounted_groups[0]._id;
              if (data.customer_delivery_address == '') {
                this.form.customer_delivery_address = {
                  address: '',
                  district: '',
                  sub_district: '',
                  province: '',
                  postal_code: '',
                };
              }
              if (data.location == '') {
                this.form.customer_address = {
                  address: '',
                  district: '',
                  sub_district: '',
                  province: '',
                  postal_code: '',
                };
              }
              if (this.isUpdate == false) {
                this.doBusinessPopup(this.form.car_id, customer_id);
              }
            }
          })
          .catch((err) => {
            console.error('err', err);
          });
      }
    },
    async getCarById(car_id, isGetCustomer = true) {
      if (car_id) {
        let body = {
          token: this.$jwt.sign({ car_id: car_id }, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/car/get_by_id`, body)
          .then(async (res) => {
            let data = res.result;
            this.car = data;

            this.temp_car.plate_character = data.plate_character;
            this.temp_car.plate_number = data.plate_number;
            this.temp_car.plate_province = data.plate_province;

            this.form.car_plate_character = data.plate_character;
            this.form.car_plate_number = data.plate_number;
            this.form.car_plate_province = data.plate_province;
            this.form.car_type = data.car_type;
            this.form.car_weight = data.car_weight;
            this.form.engine_size = data.engine_size;
            this.form.registration_date = data.registration_date;
            this.form.car_registration_url = data.car_registration_url;
            // if(this.form.status!=1){
            //   this.form.taxout_date = data.taxout_date!="Invalid date" ? data.taxout_date : this.form.taxout_date;
            // }else{
            //   this.form.taxout_date = this.form.tax_year_ended!='' ? this.form.tax_year_ended : this.form.tax_year_init;
            // }
            this.form.is_original_document = data.is_original_document;
            this.form.extra_fuel = data.extra_fuel;
            this.form.car_fuel = data.car_fuel;
            this.form.is_corporation_car = data.is_corporation_car;

            if (data.ownership.customer_id && isGetCustomer) {
              this.form.car_id = car_id;
              this.getCustomerById(data.ownership.customer_id);
            }
            // this.form.project_name = data.plate_character + ' ' + data.plate_number + ' ' + data.plate_province
          })
          .catch((err) => {
            console.error('err', err);
          });
      } else {
        this.form.car_plate_character = this.temp_car.plate_character;
        this.form.car_plate_number = this.temp_car.plate_number;
        this.form.car_plate_province = this.temp_car.plate_province;
        // this.form.project_name = this.temp_car.plate_character + ' ' + this.temp_car.plate_number + ' ' + this.temp_car.plate_province
        this.form.registration_date = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.form.taxout_date = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        this.form.tax_filling_date = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
      }
    },
    async changePlateProvince(val) {
      this.temp_car.plate_province = val;
      // this.form.project_name = this.form.project_name.split(' ').splice(0,2).join(' ')+' '+val
    },
    async getCarByInfo() {
      let body = {
        token: this.$jwt.sign(
          {
            branch_id: this.form.branch_id,
            plate_character: this.temp_car.plate_character,
            plate_number: this.temp_car.plate_number,
            plate_province: this.temp_car.plate_province,
          },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/car/get_by_info`, body)
        .then(async (res) => {
          let data = res.result;
          this.car = data;

          this.form.car_plate_character = data.plate_character;
          this.form.car_plate_number = data.plate_number;
          this.form.car_plate_province = data.plate_province;
          this.form.car_type = data.car_type || 'CT1';
          this.form.car_weight = data.car_weight;
          this.form.engine_size = data.engine_size;
          this.form.registration_date = data.registration_date;
          this.form.car_registration_url = data.car_registration_url;
          this.form.taxout_date = data.taxout_date;
          this.form.is_original_document = data.is_original_document;
          this.form.extra_fuel = data.extra_fuel;
          this.form.car_fuel = data.car_fuel;
          this.form.is_corporation_car = data.is_corporation_car;

          if (data.ownership.customer_id) {
            this.form.car_id = data._id;
            this.getCustomerById(data.ownership.customer_id);
          } else {
            (this.temp_customer = {
              _id: '',
              name: '',
            }),
            (this.customer = {
              _id: '',
              name: '',
            });
            this.form.customer_name = '';
            this.form.customer_phone_number = '';
            this.form.customer_taxpayer_number = '';
            this.form.customer_company_branch = '';
            this.form.customer_note = '';
            this.form.call_alert = false;
            this.form.call_remind_renewal = false;
            this.form.discounted_groups_id = '';
            this.form.customer_delivery_address = {
              address: '',
              district: '',
              sub_district: '',
              province: '',
              postal_code: '',
            };
            this.form.customer_address = {
              address: '',
              district: '',
              sub_district: '',
              province: '',
              postal_code: '',
            };
          }
        })
        .catch((err) => {
          console.error('err', err);
        });
      if (
        this.temp_car.plate_character != '' &&
        this.temp_car.plate_number != '' &&
        this.temp_car.plate_province != ''
      ) {
        this.form.car_id = '';
        // this.form.project_name = this.temp_car.plate_character + ' ' + this.temp_car.plate_number + ' ' + this.temp_car.plate_province
        this.form.car_plate_character = this.temp_car.plate_character;
        this.form.car_plate_number = this.temp_car.plate_number;
        this.form.car_plate_province = this.temp_car.plate_province;
      }
      this.isEditFilterExpressProduct = true;
    },
    async getDocumentNote() {
        try {
          const filter = {
            branch_id: this.$store.state.selected_branch._id,
            document_type: this.docTypeId,
          };
          const body = {
            token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
          };

          const response = await this.$axios.post(`${this.$baseUrl}/branch/get_document_note`, body);
          this.form.note = response.result.note;
        } catch (error) {
          console.error('Error fetching document note:', error);
          Sentry.captureException(error); // Log error to Sentry
        }
    },
    async getProvinces() {
     // await this.$axios.post(`${this.$baseUrl}/get_province`)
      //   .then(async(res) => {
      //       this.list.provinces = res.result;
      //   })
      //   .catch((err) => {
      //       console.log(err);
      //   });
      this.list.provinces = this.$store.state.list.provinces
    },
    async changeThirdInsurance(data) {
      this.form.product[0].broker = data.broker;
      this.form.product[0].product_id = data.product_id;
      this.form.product[0].category_id = data.category_id;
      this.form.product[0].sub_category_id = data.sub_category_id;
      this.form.product[0].sub_category_name = data.sub_category_name;
      this.form.product[0].name = data.name;
      this.form.product[0].discount = data.discount;
      this.form.product[0].price = data.price;
      this.form.product[0].product_price = data.price;
      this.form.product[0].original_product_price =
        data.original_product_price === undefined
          ? data.price
          : data.original_product_price;
      this.form.product[0].total_price = data.total_price;
      this.form.product[0].product_type = data.product_type;
      this.form.product[0].sku_code = data.sku_code;
      this.form.product[0].sku_detail = data.sku_detail;
      this.form.product[0].vat = data.vat;
      this.form.product[0].vat_price = data.vat_price;
      this.form.product[0].is_add1day = data.is_add1day;
      this.form.product[0].start_date = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.form.product[0].end_date = moment()
        .add(365, 'days')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.is_full_year = true;
      this.cal_ins_not_full_year();
    },
    async changeInsurance(data) {
      this.form.product[1].broker = data.broker;
      this.form.product[1].product_id = data.product_id;
      this.form.product[1].category_id = data.category_id;
      this.form.product[1].sub_category_id = data.sub_category_id;
      this.form.product[1].sub_category_name = data.sub_category_name;
      this.form.product[1].name = data.name;
      this.form.product[1].discount = data.discount;
      this.form.product[1].price = data.total_price;
      this.form.product[1].product_price = data.total_price;
      this.form.product[1].total_price = data.total_price;
      this.form.product[1].product_type = data.product_type;
      this.form.product[1].sku_code = data.sku_code;
      this.form.product[1].type = data.type;
      this.form.product[1].vat = data.vat;
      this.form.product[1].vat_price = data.vat_price;
      this.form.product[1].start_date = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.form.product[1].end_date = moment()
        .add(365, 'days')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.is_insurance_full_year = true;
      this.handleProductPrice(1, 'product_price', data.total_price);
    },
    async getProduct() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        discounted_groups_id: this.form.discounted_groups_id,
      };

      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/get_product`, body)
        .then(async (res) => {
          this.list.product = res.result;
        });
    },
    changeProduct(val, index) {
      this.form.product[index].category_id = val.category_id;
      this.form.product[index].detail = val.detail;
      this.form.product[index].discount = val.discount;
      this.form.product[index].message_on_select = val.message_on_select;
      this.form.product[index].name = val.name;
      this.form.product[index].product_id = val.product_id;
      this.form.product[index].product_type = val.product_type;
      this.form.product[index].sub_category_id = val.sub_category_id;
      this.form.product[index].sub_category_name = val.sub_category_name;
      this.form.product[index].tag = val.tag;
      this.form.product[index].vat = val.vat;
      this.form.product[index].product_price = val.price;
      if (val.vat == 1)
        this.form.product[index].vat_price =
          ((val.price - val.discount) * 7) / 100;
      else this.form.product[index].vat_price = 0;
      this.form.product[index].price =
        val.price + this.form.product[index].vat_price;
    },
    addProduct() {
      this.form.product.push({
        category_id: '',
        discount: 0,
        message_on_select: '',
        name: '',
        price: 0,
        product_id: '',
        product_type: '',
        sub_category_id: '',
        sub_category_name: '',
        vat: 0,
        vat_price: 0,
        product_price: 0,
      });
    },
    deleteProduct(index) {
      this.form.product.splice(index, 1);
      if (index == 0) {
        let data = {
          broker: '',
          category_id: 'PC1',
          discount: 0,
          broker: '',
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sku_code: '',
          sku_detail: '',
          sub_category_id: '',
          sub_category_name: '',
          total_price: 0,
          type: 0,
          vat: 0,
          vat_price: 0,
          start_date: moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_date: moment()
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          product_price: 0,
        };
        this.form.product.splice(0, 0, data);
      } else if (index == 1) {
        let data = {
          category_id: 'PC2',
          discount: 0,
          broker: '',
          name: '',
          sku_code: '',
          price: 0,
          product_id: '',
          product_type: '',
          sub_category_id: '',
          sub_category_name: '',
          total_price: 0,
          type: 0,
          vat: 0,
          vat_price: 0,
          start_date: moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          end_date: moment()
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          product_price: 0,
        };
        this.form.product.splice(1, 0, data);
      } else if (this.form.product.length == 1) {
        this.addProduct();
      }
    },
    async getApproval(text = '') {
      let filter = {
        item_per_page: 50,
        page: 1,
        start_with: text,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/users/get_user`, body)
        .then((res) => {
          this.list.approval = res.result.data;
        })
        .catch((err) => {
          console.error('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getCustomers(text = '') {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        start_with: text,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/customer/get_customer_list`, body)
        .then((res) => {
          if (!(this.temp_customer.name == '')) {
            this.list.filter_customers = [].concat(this.temp_customer, res.result)
          } else if(this.temp_customer.name=='') {
            this.list.filter_customers = res.result
          }
        })
        .catch((err) => {
          console.error('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getCars(text = '') {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        item_per_page: 10,
        page: 1,
        start_with: text,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/car/get_car`, body)
        .then((res) => {
          if (
            !(
              this.temp_car.plate_character === '' &&
              this.temp_car.plate_number === ''
            )
          ) {
            this.list.cars = [this.temp_car];
            for (let val of res.result.data) {
              this.list.cars.push(val);
            }
          } else {
            this.list.cars = res.result.data;
          }
        })
        .catch((err) => {
          console.error('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getDataPrint(id = this.$route.params.id) {
      let branch = this.thisBranch;
      let filter = {
        document_id: id,
        branch: {
          is_split_bill: branch.is_split_bill,
          logo_url: branch.logo_url,
          open_hours: branch.open_hours,
          name_for_receipt: branch.name_for_receipt,
          taxpayer_number: branch.taxpayer_number,
          phone_number: branch.phone_number,
          line_id: branch.line_id,
          facebook: branch.facebook,
          location: branch.location,
          company_branch: branch.company_branch,
          qr_code_payment: branch.qr_code_payment,
          split_bill_data: branch.split_bill_data,
          setting: {
            merging_subcategory: branch.setting.merging_subcategory,
            receipt_printing_format: branch.setting.receipt_printing_format,
            enable_product_merging: branch.setting.enable_product_merging
          },
          split_bill_category: branch.split_bill_category,
        }
        };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/print_document_data`, body)
        .then(async (res) => {
          this.dataPrint = res.result;
          this.printKey++
        })
        .catch((err) => {
          console.error('err', err);
        });
    },
    handleApproval() {
      setTimeout(() => {
        let text = this.form.approval || '';
        if (String(text).length == 0) {
          this.approvals = null;

          this.form.approval_id = '';
          this.form.approval = '';
        }
        this.getApproval(text);
      }, 250);
    },
    handleCars(key) {
      setTimeout(() => {
        let text = this.form[key] || '';
        this.temp_car[key.replace('car_', '')] = this.form[key];
        if (String(text).length == 0) {
          this.car = null;
          this.form.car_plate_character = '';
          this.form.car_plate_number = '';
          this.form.car_plate_province = '';
          this.form.car_type = 'CT1';
          this.form.car_weight = '';
          this.form.engine_size = '';
          this.form.registration_date = '';
          this.form.taxout_date = '';
          this.form.tax_filling_date = '';
          this.form.is_original_document = false;
          this.form.extra_fuel = 0;
          this.form.is_corporation_car = false;
        }
        this.getCars(text);
      }, 250);
    },
    handleCarsPlate() {
      setTimeout(() => {
        this.getCars(this.temp_car.plate_character);
      }, 250);
    },
    handleCarsInfo() {
      setTimeout(() => {
        this.getCarByInfo();
      }, 250);
    },
    handleCustomer() {
      setTimeout(() => {        
        this.temp_customer.name = this.customer_name_search || '';
        if (!(this.temp_customer.name == '')) {
          this.form.customer_name = this.customer_name_search;
          this.getCustomers(this.customer_name_search);
        } else if(this.temp_customer.name=='') {
          this.list.filter_customers.unshift(this.temp_customer);
          this.getCustomers('');
        }
      }, 250);
    },
    async handleProductAllChecked(checked) {
      if (!this.car) this.isEditFilterExpressProduct = true;
      var inspect_checked = false;
      if (checked) {
        if (['CT1', 'CT2', 'CT3', 'CT4'].indexOf(this.form.car_type) >= 0) {
          let diff1 = moment.duration(
            moment(this.form.tax_filling_date).diff(
              moment(this.form.taxout_date)
            )
          );
          let diff2 = moment.duration(
            moment().diff(moment(this.form.registration_date))
          );
          // ขาดต่อทะเบียนเกิน 1 ปี
          if (diff1.asYears() > 1) inspect_checked = true;
          //  รถรย.12 ที่มี Z > 4 ปี 9 เดือน 1 วัน
          if (this.form.car_type == 'CT4') {
            if (
              diff2.asYears() >= 4 &&
              diff2.asMonths() - diff2.asYears() * 12 >= 9
            )
              inspect_checked = true;
            // รถรย.1, 2, 3 ที่มี Z > 6 ปี 9 เดือน 1 วัน
          } else {
            if (
              diff2.asYears() >= 6 &&
              diff2.asMonths() - diff2.asYears() * 12 >= 9
            )
              inspect_checked = true;
          }
          if (inspect_checked) {
            this.product_selected = ['inspect', 'tax', 'third_insurand'];
          } else {
            this.product_selected = ['tax', 'third_insurand'];
          }
        } else {
          this.product_selected = ['inspect', 'tax', 'third_insurand'];
        }
      } else this.product_selected = [];

      await this.getExpressProduct();
    },
    async handleProductChecked() {
      if (!this.car) this.isEditFilterExpressProduct = true;

      if (this.product_selected.length == 3) this.product_all = true;
      else this.product_all = false;

      await this.getExpressProduct();
    },
    async filterExpressProduct(data) {
      if (data) {
        var index = 0;
        let filtered = this.form.product.filter((item, i) => {
          if (item.product_id == data.product_id) index = i;
          return item.product_id == data.product_id;
        });

        if (filtered.length == 0) {
          this.form.product.push(data);
        } else {
          this.form.product[index] = data;
        }
      }
    },
    async filterListProduct(data) {
      if (data) {
        await this.list.product.forEach((item, i) => {
          if (item.product_id == data.product_id) {
            this.list.product[i] = { ...data };
          }
        });
      }
    },
    async handleExpressProduct() {
      this.isLoadingProduct = true;
      await this.main_calculate();

      var deleteIdList = [];
      this.list.alert = [];

      if (typeof this.list.expressProduct.tax_product !== 'undefined') {
        await this.handle_price_tax_product();
        await this.filterListProduct(this.list.expressProduct.tax_product);
      }

      if (this.product_selected.indexOf('inspect') == -1) {
        deleteIdList.push(this.list.expressProduct.inspect_product.product_id);
      } else {
        await this.filterExpressProduct(
          this.list.expressProduct.inspect_product
        );
      }
      if (this.product_selected.indexOf('tax') == -1) {
        deleteIdList.push(this.list.expressProduct.tax_product.product_id);
        if (this.list.expressProduct.extension_tax_fee_product)
          deleteIdList.push(
            this.list.expressProduct.extension_tax_fee_product.product_id
          );
      } else {
        if (this.list.expressProduct.tax_product) {
          // await this.handle_price_tax_product();
          await this.filterExpressProduct(this.list.expressProduct.tax_product);
        }
        await this.filterExpressProduct(
          this.list.expressProduct.extension_tax_fee_product
        );
        this.form.show_pickup = true;
      }

      if (this.product_selected.indexOf('third_insurand') == -1) {
        if (this.list.expressProduct.motorcycle_3rdinsurance) {
          deleteIdList.push(
            this.list.expressProduct.motorcycle_3rdinsurance.product_id
          );
        }
      } else {
        await this.filterExpressProduct(
          this.list.expressProduct.motorcycle_3rdinsurance
        );
      }

      this.form.product = await this.form.product.filter((item) => {
        return deleteIdList.indexOf(item.product_id) == -1;
      });

      if (
        this.form.product.length > 3 &&
        !this.form.product[2].name &&
        !this.form.product[2].detail &&
        !this.form.product[2].price
      ) {
        this.form.product.splice(2, 1);
      }

      if (this.form.product.length != 3 && !this.form.product[2]) {
        this.form.product.push({
          category_id: '',
          discount: 0,
          message_on_select: '',
          name: '',
          price: 0,
          product_id: '',
          product_type: '',
          sub_category_id: '',
          sub_category_name: '',
          vat: 0,
          vat_price: 0,
          product_price: 0,
        });
      }

      if (this.product_selected.indexOf('third_insurand') == -1) {
        this.form.product[0].broker = '';
        this.form.product[0].category_id = '';
        this.form.product[0].discount = 0;
        this.form.product[0].name = '';
        this.form.product[0].price = 0;
        this.form.product[0].product_id = '';
        this.form.product[0].product_type = '';
        this.form.product[0].sku_code = '';
        this.form.product[0].sku_detail = '';
        this.form.product[0].sub_category_id = '';
        this.form.product[0].sub_category_name = '';
        this.form.product[0].vat = 0;
        this.form.product[0].vat_price = 0;
        this.form.product[0].product_price = 0;
        this.form.product[0].original_product_price = 0;
        this.form.product[0].total_price = 0;
        this.form.product[0].is_add1day = 0;
      } else {
        if (this.list.expressProduct.third_insurand_product) {
          if (
            this.form.product[0].product_id !=
            this.list.expressProduct.third_insurand_product.product_id
          ) {
            let price =
              this.list.expressProduct.third_insurand_product.total_price -
              this.list.expressProduct.third_insurand_product.discount;
            if (this.list.setting.third_insurance_tax_scrap == 1)
              price = Math.floor(price);
            else if (this.list.setting.third_insurance_tax_scrap == 2)
              price = Math.ceil(price);
            else if (this.list.setting.third_insurance_tax_scrap >= 3) {
              price = Math.floor(price);
              var price1 = 5 - (price % 5);
              if (this.list.setting.third_insurance_tax_scrap == 3)
                price += price1;
              else if (this.list.setting.third_insurance_tax_scrap == 4)
                price += price1 + 5;
              else if (this.list.setting.third_insurance_tax_scrap == 5)
                price += price1 + 15;
            }
            // this.is_full_year = false
            this.form.product[0].broker =
              this.list.expressProduct.third_insurand_product.broker;
            await this.getThirdInsuranceProduct(
              this.list.expressProduct.third_insurand_product.sub_category_id
            );
            this.form.product[0].product_id =
              this.list.expressProduct.third_insurand_product.product_id;
            this.form.product[0].category_id =
              this.list.expressProduct.third_insurand_product.category_id;
            this.form.product[0].sub_category_id =
              this.list.expressProduct.third_insurand_product.sub_category_id;
            this.form.product[0].sub_category_name =
              this.list.expressProduct.third_insurand_product.sub_category_name;
            this.form.product[0].name =
              this.list.expressProduct.third_insurand_product.name;
            this.form.product[0].product_type =
              this.list.expressProduct.third_insurand_product.product_type;
            this.form.product[0].sku_code =
              this.list.expressProduct.third_insurand_product.sku_code;
            this.form.product[0].sku_detail =
              this.list.expressProduct.third_insurand_product.sku_detail;
            this.form.product[0].vat =
              this.list.expressProduct.third_insurand_product.vat;
            this.form.product[0].vat_price =
              this.list.expressProduct.third_insurand_product.vat_price;
            this.form.product[0].discount =
              this.list.expressProduct.third_insurand_product.discount;
            this.form.product[0].price = price;
            this.form.product[0].product_price = price;
            this.form.product[0].original_product_price =
              this.list.expressProduct.third_insurand_product.price;
            this.form.product[0].total_price =
              this.list.expressProduct.third_insurand_product.total_price;
            this.form.product[0].is_add1day =
              this.list.expressProduct.third_insurand_product.is_add1day;
            this.form.product[0].start_date = moment()
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DDTHH:mm:ss');
            this.form.product[0].end_date = moment()
              .add(365, 'days')
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DDTHH:mm:ss');
            this.is_full_year = true;
          }
        }
      }
      this.isLoadingProduct = false;
    },
    async getExpressProduct() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        discounted_groups_id: this.form.discounted_groups_id,
        car_type: this.form.car_type,
        car_weight: this.form.car_weight,
        engine_size: this.form.engine_size,
        extra_fuel: this.form.extra_fuel,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/get_express_product`, body)
        .then(async (res) => {
          let data = res.result;

          this.isEditFilterExpressProduct = false;

          if (typeof data.inspect_product !== 'undefined')
            this.list.expressProduct.inspect_product = {
              ...data.inspect_product,
              product_price: data.inspect_product.price,
            };
          else this.list.expressProduct.inspect_product = null;

          if (typeof data.tax_product !== 'undefined')
            this.list.expressProduct.tax_product = {
              ...data.tax_product,
              product_price: data.tax_product.price,
            };
          else this.list.expressProduct.tax_product = null;

          if (typeof data.third_insurand_product !== 'undefined' 
                && data.third_insurand_product!=null)
            this.list.expressProduct.third_insurand_product = {
              ...data.third_insurand_product,
              product_price: data.third_insurand_product.price,
            };
          else this.list.expressProduct.third_insurand_product = null;
          if(data.third_insurand_product==null && this.product_selected.indexOf('third_insurand') != -1){
            this.product_selected = this.product_selected.filter(item => item !== 'third_insurand')
            this.$alertServerError({ title: 'หมวดหมู่หรือสินค้าของ พ.ร.บ. สินค้าด่วนถูกปิดไว้' });
          }

          if (typeof data.extension_tax_fee_product !== 'undefined')
            this.list.expressProduct.extension_tax_fee_product = {
              ...data.extension_tax_fee_product,
              product_price: data.extension_tax_fee_product.price,
            };
          else this.list.expressProduct.extension_tax_fee_product = null;

          if (typeof data.motorcycle_3rdinsurance !== 'undefined')
            this.list.expressProduct.motorcycle_3rdinsurance = {
              ...data.motorcycle_3rdinsurance,
              product_price: data.motorcycle_3rdinsurance.price,
            };
          else this.list.expressProduct.motorcycle_3rdinsurance = null;
        })
        .catch((err) => {
          console.error('err', err);
        });
      this.handleExpressProduct();
    },
    async handle_price_tax_product() {
      if (typeof this.list.expressProduct.tax_product !== 'undefined') {
        this.list.expressProduct.tax_product.price = this.car_tax.net_tax_price;
        this.list.expressProduct.tax_product.product_price =
          this.list.expressProduct.tax_product.price;
      }
    },
    handleProductPrice(index, key = 'discount', val) {
      if (!val.toString().includes(',')) {
        var product_price = this.form.product[index].product_price;
        var discount = this.form.product[index].discount;
        var vat = this.form.product[index].vat;
        var vat_price = this.form.product[index].vat_price;
        var price = this.form.product[index].price;

        if (index == 0) {
          if (key == 'discount') {
            discount = val === '' || val === null ? 0 : parseFloat(val);
            price = product_price - discount;
            vat_price = 0;
          } else if (key == 'product_price') {
            product_price = val === '' || val === null ? 0 : parseFloat(val);
            price = product_price - discount;
          }
        } else if (index == 1) {
          if (key == 'discount') {
            discount = val === '' || val === null ? 0 : parseFloat(val);
            price = product_price - discount;
            vat_price = 0;
          } else if (key == 'product_price') {
            product_price = val === '' || val === null ? 0 : parseFloat(val);
            vat_price = parseFloat(
              parseFloat(product_price - product_price / 1.07).toFixed(2)
            );
            price = product_price - discount;
          }
        } else {
          if (key == 'discount')
            discount = val === '' || val === null ? 0 : parseFloat(val);
          else if (key == 'vat')
            vat = val === '' || val === null ? 0 : parseFloat(val);
          else if (key == 'product_price')
            product_price = val === '' || val === null ? 0 : parseFloat(val);

          if (vat == 1) vat_price = ((product_price - discount) * 7) / 100;
          else vat_price = 0;

          price = product_price - discount + vat_price;
        }
        this.form.product[index].product_price = parseFloat(
          parseFloat(product_price).toFixed(2)
        );
        this.form.product[index].discount = parseFloat(
          parseFloat(discount).toFixed(2)
        );
        this.form.product[index].price = parseFloat(
          parseFloat(price).toFixed(2)
        );
        this.form.product[index].vat_price =
          index != 0
            ? parseFloat(parseFloat(vat_price).toFixed(2))
            : this.form.product[index].vat_price;
      }
    },
    // Main Calculate Start Here >>>
    async main_calculate() {
      this.car_tax.late_age = await this.find_age(
        this.form.tax_filling_date,
        this.form.taxout_date
      );
      this.car_tax.car_age = await this.find_age(
        this.form.taxout_date,
        this.form.registration_date
      );

      this.tax_car_cal(
        this.form.car_type,
        this.form.car_weight,
        this.form.engine_size
      );
      await this.check_for_inspection();

      if (this.form.car_type == 'CT1' && this.form.car_fuel != 3) {
        await this.CT1_car_age();
      } else {
        for (var i = 0; i < 4; i++) {
          this.car_tax.tax_by_year[i] = this.car_tax.tax_price[0];
        }
      }
      await this.total_late_tax();
      await this.one_year_tax();
      this.car_tax.net_tax_price = this.car_tax.total_tax_price[0]; //นำค่าภาษีค่าปรับมาแสดงหน้าเว็บ
      if (this.isActiveTaxYearEnd) {
        await this.tax_year_ended_true();
      }
      await this.tax_dis_or_inc();
      let tax_price =
        Math.ceil((this.car_tax.net_tax_price + Number.EPSILON) * 100) / 100;
      this.car_tax.net_tax_price = tax_price == 0.01 ? 0 : tax_price;
      if (this.list.setting.car_tax_scrap == 1)
        this.car_tax.net_tax_price = Math.floor(this.car_tax.net_tax_price);
      else if (this.list.setting.car_tax_scrap == 2)
        this.car_tax.net_tax_price = Math.ceil(this.car_tax.net_tax_price);
    },
    //พิมพ์ค่าออกมาเช็ค
    printf_s() {
      this.car_tax.text_alert =
        '<h3>ค่าภาษีรวมค่าปรับ ทั้งหมดที่ต้องเสีย ' +
        this.car_tax.net_tax_price +
        ' บาท </h3></br>';

      this.car_tax.text_alert +=
        'ค่าภาษีปี tax_year_inti = ' +
        this.car_tax.total_tax_price[0] +
        ' บาท </br>';
      this.car_tax.text_alert +=
        'ปี พ.ศ. tax_year_inti = ' + this.car_tax.total_tax_price[1] + '</br>';
      this.car_tax.text_alert += '</br>';

      this.car_tax.text_alert +=
        'ค่าภาษีปี tax_year_ended = ' +
        this.car_tax.total_tax_price[2] +
        ' บาท</br>';
      this.car_tax.text_alert +=
        'ปี พ.ศ. tax_year_ended = ' + this.car_tax.total_tax_price[3] + '</br>';
      this.car_tax.text_alert += '</br>';

      this.car_tax.text_alert += 'ข้อความแจ้งเตือน</br>';
      for (let i = 0; i < 5; i++) {
        this.car_tax.text_alert +=
          'ข้อความ Pop Up แจ้งเตือน [' +
          i +
          '] = ' +
          this.car_tax.popup_massage[i] +
          '</br>';
        if (this.car_tax.popup_massage[i]) {
          this.list.alert.push(this.car_tax.popup_massage[i]);
        }
      }
      let diff = moment.duration(
        moment(this.car_tax.tax_year_init).diff(
          moment(this.form.tax_filling_date)
        )
      );
      this.car_tax.text_alert += 'diff.asDays() : ' + diff.asDays() + '</br>';

      this.car_tax.text_alert +=
        'ภาษีขาดมาแล้ว = ' + this.car_tax.late_age + ' เดือน </br>';
      this.car_tax.text_alert +=
        'อายุรถ จากวันจดทะเบียนถึงวันภาษีหมดอายุ : ' +
        this.car_tax.car_age +
        ' เดือน </br></br>';

      this.car_tax.text_alert += 'ค่าภาษีรถ (ข้อมูลดิบ จากรย. รถ)</br>';
      for (let i = 0; i < 4; i++) {
        this.car_tax.text_alert +=
          'tax_by_year[' +
          i +
          '] = ' +
          this.car_tax.tax_by_year[i] +
          ' บาท </br>';
      }
    },

    // ฟังก์ชั่นที่จะเรียกใช้งาน
    // อยู่ข้างล่่างนี้ทั้งหมด

    // คำนวณวัน แบบใหม่
    find_age(dateOne, dateTwo) {
      let diffYear = moment(dateOne).year() - moment(dateTwo).year();
      let diffMonth =
        moment(dateOne).month() +
        1 -
        (moment(dateTwo).month() + 1) +
        diffYear * 12;
      let diffDay = moment(dateOne).date() - moment(dateTwo).date();
      if (diffDay > 0) diffMonth++;
      return diffMonth;
    },
    //ถ้ากดปุ่ม tax_year_ended ให้เรียกใช้ฟังก์ชั่นนี้ และส่งค่า tax_year_ended ที่เป็นค่าจริงกลับไป Server ถ้ามีค่าแต่ user ไม่กดปุ่ม ก็ให้ส่ง "" กลับไป Server
    tax_year_ended_true() {
      this.car_tax.net_tax_price =
        this.car_tax.total_tax_price[0] + this.car_tax.total_tax_price[2];
    },
    //ฟังก์ชั่นเช็ค และแจ้งเตือนรถที่ต้องตรวจสภาพ
    check_for_inspection() {
      var age_inspec = this.find_age(moment(), this.form.registration_date);
      var age_inspec_tax = this.find_age(moment(), this.form.taxout_date);

      if (
        (age_inspec_tax > 12 && this.form.car_type == 'CT1') ||
        (age_inspec_tax > 12 && this.form.car_type == 'CT2') ||
        (age_inspec_tax > 12 && this.form.car_type == 'CT3') ||
        (age_inspec_tax > 12 && this.form.car_type == 'CT4')
      ) {
        this.car_tax.popup_massage[4] = 'รถขาดเกินปี ต้องตรวจสภาพด้วย';
      } else if (
        (age_inspec < 81 && this.form.car_type == 'CT1') ||
        (age_inspec < 81 && this.form.car_type == 'CT2') ||
        (age_inspec < 81 && this.form.car_type == 'CT3')
      ) {
        this.car_tax.popup_massage[4] = 'รถยังไม่ถึงเกณฑ์ที่ต้องตรวจสภาพ';
      } else if (age_inspec < 57 && this.form.car_type == 'CT4') {
        this.car_tax.popup_massage[4] = 'รถยังไม่ถึงเกณฑ์ที่ต้องตรวจสภาพ';
      } else {
        this.car_tax.popup_massage[4] = 'รถถึงเกณฑ์ที่ต้องตรวจสภาพ';
      }
    },
    //ฟังก์ชั่นคำนวณส่วนลดค่าแก๊สและรถนิติบุคคล
    tax_dis_or_inc() {
      var price = this.car_tax.net_tax_price;
      var total_tax = this.total_tax;
      if (this.form.extra_fuel == 2) {
        // LPG
        this.car_tax.popup_massage[3] = 'อย่าลืมใบแก๊สด้วย'; //เช็คการแจ้งเตือนจากหน้าตั้งค่า gas_inspection_alert
      } else if (this.form.extra_fuel == 1) {
        // CNG
        this.car_tax.popup_massage[3] = 'อย่าลืมใบแก๊สด้วย ลดภาษี 25%';
        this.car_tax.net_tax_price = price - price / 4;
        price = this.car_tax.net_tax_price;
        this.total_tax = total_tax - total_tax / 4;
        total_tax = this.total_tax;
      } else if (this.form.extra_fuel == 3) {
        // GAS 100%
        this.car_tax.popup_massage[3] = 'อย่าลืมใบแก๊สด้วย ลดภาษี 50%';
        this.car_tax.net_tax_price = price - price / 2;
        price = this.car_tax.net_tax_price;
        this.total_tax = total_tax - total_tax / 2;
        total_tax = this.total_tax;
      } else if (this.form.car_fuel == 3 && this.form.car_type != 'CT1') {
        this.car_tax.popup_massage[3] = 'ได้รับส่วนลด ภาษีรถไฟฟ้า';
        this.car_tax.net_tax_price = price - price / 2;
        price = this.car_tax.net_tax_price;
        this.total_tax = total_tax - total_tax / 2;
        total_tax = this.total_tax;
      } else {
        this.car_tax.popup_massage[3] = '';
        this.car_tax.net_tax_price = price;
      }
      if (this.form.is_corporation_car == true && this.form.car_type == 'CT1') {
        // Corporation car
        this.car_tax.net_tax_price = price * 2;
        this.total_tax = total_tax * 2;
      }
    },
    //ฟังก์ชั่นคำนวณราคาพรบ. แบบไม่เต็มปี
    cal_ins_not_full_year() {
      if (this.form.product[0].price != 0) {
        var is_add1day = this.form.product[0].is_add1day
          ? this.is_full_year
            ? 0
            : 1
          : 0;
        var diff_ins_date = moment
          .duration(
            moment(this.form.product[0].end_date).diff(
              moment(this.form.product[0].start_date)
            )
          )
          .asDays();
          
        var price_all_day = this.form.product[0].original_product_price / 365;
        var pxdiff = Number(price_all_day * (diff_ins_date + is_add1day));
        var duty = Math.ceil(pxdiff * 0.004);
        var vat_for_ins = (pxdiff + duty) * 0.07;
        if (!this.is_full_year) {
        var price =
          Math.ceil(
            (parseFloat(pxdiff + duty + vat_for_ins) + Number.EPSILON) * 100
          ) / 100;
        this.form.product[0].insurance_premium = price;
        } else {
          price = this.form.product[0].total_price;
          this.form.product[0].insurance_premium = price;
        }

        if (this.list.setting.third_insurance_tax_scrap == 1)
          price = Math.floor(price);
        else if (this.list.setting.third_insurance_tax_scrap == 2)
          price = Math.ceil(price);
        else if (this.list.setting.third_insurance_tax_scrap >= 3) {
          price = Math.floor(price);
          var price1 = 5 - (price % 5);
          if (this.list.setting.third_insurance_tax_scrap == 3) price += price1;
          else if (this.list.setting.third_insurance_tax_scrap == 4)
            price += price1 + 5;
          else if (this.list.setting.third_insurance_tax_scrap == 5)
            price += price1 + 15;
        }

        this.form.product[0].vat_price = vat_for_ins;
        this.handleProductPrice(0, 'product_price', price);
      }
    },
    // ฟังก์ชั่นคำนวณหาวันที่จะต่อล่วงหน้า และกรอกรถที่ยังต่อไม่ได้
    one_year_tax() {
      let diff = moment.duration(
        moment(this.car_tax.tax_year_init, 'YYYY-MM-DDTHH:mm:ss').diff(
          moment(this.form.tax_filling_date, 'YYYY-MM-DDTHH:mm:ss')
        )
      );
      let asday_can_pay = moment(this.form.taxout_date, 'YYYY-MM-DDTHH:mm:ss')
        .subtract(3, 'months')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');

      if (diff.asMonths() > 15) {
        this.car_tax.popup_massage[0] =
          'ไม่สามารถต่อได้ เพราะต่อภาษีล่วงหน้าได้ 3 เดือน สามารถต่อได้หลังวันที่ ' +
          moment(asday_can_pay).add(543, 'year').format('DD/MM/YYYY');
        this.car_tax.tax_year_ended = null;
        this.car_tax.total_tax_price[3] = 0;
      } else if (diff.asMonths() > 3) {
        this.car_tax.popup_massage[0] = '';
        this.car_tax.tax_year_ended = null;
        this.car_tax.total_tax_price[3] = 0;
      } else if (diff.asMonths() >= 0 && diff.asMonths() <= 3) {
        this.car_tax.total_tax_price[2] = this.car_tax.tax_by_year[3];
        this.car_tax.tax_year_ended = moment(
          this.car_tax.tax_year_init,
          'YYYY-MM-DDTHH:mm:ss'
        )
          .add(1, 'years')
          .format('YYYY-MM-DDTHH:mm:ss');
        this.car_tax.total_tax_price[3] = parseInt(
          moment(this.car_tax.tax_year_ended, 'YYYY-MM-DDTHH:mm:ss').format(
            'YYYY'
          )
        );
        this.car_tax.popup_massage[0] =
          'รถคันนี้สามารถต่อได้ถึงปี ' +
          (this.car_tax.total_tax_price[3] + 543);
      }
    },
    //ฟังก์ชั่นคำนวณค่าภาษีเก่า + ค่าปรับย้อนหลัง ของ CT2, CT3, CT4, CT5 เท่านั้น
    total_late_tax() {
      var tax_penalty = 0;
      var total_tax = 0;
      if (this.car_tax.late_age > 36) {
        this.car_tax.popup_massage[1] =
          'รถโดน ระงับ ขาดเกิน 3 ปี ค่าภาษีที่แสดง มีค่าปรับ 3 ปี + ค่าภาษีปีใหม่';
        tax_penalty =
          this.car_tax.tax_by_year[0] * 0.01 * 36 +
          this.car_tax.tax_by_year[1] * 0.01 * 24 +
          this.car_tax.tax_by_year[2] * 0.01 * 12;
        total_tax =
          this.car_tax.tax_by_year[0] +
          this.car_tax.tax_by_year[1] +
          this.car_tax.tax_by_year[2] +
          this.car_tax.tax_by_year[3];
        this.car_tax.total_tax_price[0] = tax_penalty + total_tax;
        this.tax_year_plus_one(3);
      } else if (this.car_tax.late_age > 24) {
        var x = this.car_tax.late_age - 24;
        tax_penalty =
          this.car_tax.tax_by_year[0] * 0.01 * (24 + x) +
          this.car_tax.tax_by_year[1] * 0.01 * (12 + x) +
          this.car_tax.tax_by_year[2] * 0.01 * x;
        total_tax =
          this.car_tax.tax_by_year[0] +
          this.car_tax.tax_by_year[1] +
          this.car_tax.tax_by_year[2];
        this.car_tax.total_tax_price[0] = tax_penalty + total_tax;
        this.tax_year_plus_one(3);
      } else if (this.car_tax.late_age > 12) {
        var x = this.car_tax.late_age - 12;
        tax_penalty =
          this.car_tax.tax_by_year[1] * 0.01 * (12 + x) +
          this.car_tax.tax_by_year[2] * 0.01 * x;
        total_tax = this.car_tax.tax_by_year[1] + this.car_tax.tax_by_year[2];
        this.car_tax.total_tax_price[0] = tax_penalty + total_tax;
        this.tax_year_plus_one(2);
      } else if (this.car_tax.late_age > 0) {
        tax_penalty =
          this.car_tax.tax_by_year[2] * 0.01 * this.car_tax.late_age;
        total_tax = this.car_tax.tax_by_year[2];
        this.car_tax.total_tax_price[0] = tax_penalty + total_tax;
        this.tax_year_plus_one(1);
      } else {
        total_tax = this.car_tax.tax_by_year[3];
        this.car_tax.total_tax_price[0] = this.car_tax.tax_by_year[3];
        this.tax_year_plus_one(1);
      }
      this.tax_penalty = tax_penalty;

      this.total_tax = this.isActiveTaxYearEnd ? total_tax + this.car_tax.tax_by_year[3] : total_tax;
      tax_penalty = Math.ceil((this.tax_penalty + Number.EPSILON) * 100) / 100;
      this.tax_penalty = tax_penalty == 0.01 ? 0 : tax_penalty;
      if (this.list.setting.car_tax_scrap == 1)
        this.tax_penalty = Math.floor(this.tax_penalty);
      else if (this.list.setting.car_tax_scrap == 2)
        this.tax_penalty = Math.ceil(this.tax_penalty);
      total_tax = Math.ceil((this.total_tax + Number.EPSILON) * 100) / 100;
      this.total_tax = total_tax == 0.01 ? 0 : total_tax;
      if (this.list.setting.car_tax_scrap == 1)
        this.total_tax = Math.floor(this.total_tax);
      else if (this.list.setting.car_tax_scrap == 2)
        this.total_tax = Math.ceil(this.total_tax);

      this.car_tax.total_tax_price[1] = parseInt(
        moment(this.car_tax.tax_year_init, 'YYYY-MM-DDTHH:mm:ss').format('YYYY')
      );
    },
    //ฟังก์ชั่น +1 ให้กับปีต่อภาษีที่ขาดล่าสุด เพื่อมาเป็นปีปัจจุบัน ไว้ใช้ใน total_late_tax()
    tax_year_plus_one(i) {
      this.car_tax.tax_year_init = moment(this.form.taxout_date)
        .add(i, 'years')
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    //ฟังก์ชั่นคำนวณหาค่าภาษี
    tax_car_cal(car_type, car_weight, engine_size) {
      var x = 0;
      var z = 0;
      //รถ รย.12
      if (car_type == 'CT4') {
        this.car_tax.tax_price[0] = 100;
      } //รถ รย.3
      else if (car_type == 'CT3') {
        if (car_weight > 2000) {
          this.car_tax.tax_price[0] = 1650;
        } else if (car_weight > 1750) {
          this.car_tax.tax_price[0] = 1350;
        } else if (car_weight > 1500) {
          this.car_tax.tax_price[0] = 1050;
        } else if (car_weight > 1250) {
          this.car_tax.tax_price[0] = 900;
        } else if (car_weight > 1000) {
          this.car_tax.tax_price[0] = 750;
        } else if (car_weight > 750) {
          this.car_tax.tax_price[0] = 600;
        } else if (car_weight > 500) {
          this.car_tax.tax_price[0] = 450;
        } else {
          this.car_tax.tax_price[0] = 300;
        }
      } //รถ รย.2
      else if (
        car_type == 'CT2' ||
        (this.form.car_fuel == 3 && car_type == 'CT1')
      ) {
        // this.car_tax.popup_massage[2] = ''; //จะแสดง popup ตัวนี้ไหม ขึ้นอยู่กับการตั้งค่าหน้าธุรกิจ alert_onselect_cartype2
        if (car_weight > 7000) {
          this.car_tax.tax_price[0] = 3600;
        } else if (car_weight > 6000) {
          this.car_tax.tax_price[0] = 3400;
        } else if (car_weight > 5000) {
          this.car_tax.tax_price[0] = 3200;
        } else if (car_weight > 4500) {
          this.car_tax.tax_price[0] = 3000;
        } else if (car_weight > 4000) {
          this.car_tax.tax_price[0] = 2800;
        } else if (car_weight > 3500) {
          this.car_tax.tax_price[0] = 2600;
        } else if (car_weight > 3000) {
          this.car_tax.tax_price[0] = 2400;
        } else if (car_weight > 2500) {
          this.car_tax.tax_price[0] = 2200;
        } else if (car_weight > 2000) {
          this.car_tax.tax_price[0] = 1900;
        } else if (car_weight > 1750) {
          this.car_tax.tax_price[0] = 1600;
        } else if (car_weight > 1500) {
          this.car_tax.tax_price[0] = 1300;
        } else if (car_weight > 1250) {
          this.car_tax.tax_price[0] = 1000;
        } else if (car_weight > 1000) {
          this.car_tax.tax_price[0] = 800;
        } else if (car_weight > 750) {
          this.car_tax.tax_price[0] = 450;
        } else if (car_weight > 500) {
          this.car_tax.tax_price[0] = 300;
        } else {
          this.car_tax.tax_price[0] = 150;
        }
      } //รถ รย.1
      else if (car_type == 'CT1') {
        if (engine_size > 1800) {
          x = 2100 + (engine_size - 1800) * 4;
        } else if (engine_size > 600) {
          x = 300 + (engine_size - 600) * 1.5;
        } else x = engine_size * 0.5;

        for (var i = 0; i < 6; i++) {
          z = x - (x * i * 10) / 100;
          this.car_tax.tax_price[i] = z;
        }
      } else if (car_type == 'CT5') {
        this.car_tax.tax_price[0] = 50;
      } //CT6 for รถโดยสารประจำทาง (10-19)
      else if (car_type == 'CT6') {
        if (car_weight > 7000) {
          this.car_tax.tax_price[0] = 2900;
        } else if (car_weight > 6000) {
          this.car_tax.tax_price[0] = 2700;
        } else if (car_weight > 5000) {
          this.car_tax.tax_price[0] = 2500;
        } else if (car_weight > 4500) {
          this.car_tax.tax_price[0] = 2300;
        } else if (car_weight > 4000) {
          this.car_tax.tax_price[0] = 2100;
        } else if (car_weight > 3500) {
          this.car_tax.tax_price[0] = 1900;
        } else if (car_weight > 3000) {
          this.car_tax.tax_price[0] = 1700;
        } else if (car_weight > 2500) {
          this.car_tax.tax_price[0] = 1500;
        } else if (car_weight > 2000) {
          this.car_tax.tax_price[0] = 1300;
        } else if (car_weight > 1750) {
          this.car_tax.tax_price[0] = 1100;
        } else if (car_weight > 1500) {
          this.car_tax.tax_price[0] = 900;
        } else if (car_weight > 1250) {
          this.car_tax.tax_price[0] = 700;
        } else if (car_weight > 1000) {
          this.car_tax.tax_price[0] = 600;
        } else if (car_weight > 750) {
          this.car_tax.tax_price[0] = 500;
        } else if (car_weight > 500) {
          this.car_tax.tax_price[0] = 400;
        } else {
          this.car_tax.tax_price[0] = 300;
        }
      } //CT9 CT7 for รถโดยสารประจำทาง (10-19)
      else if (car_type == 'CT7' || car_type == 'CT9') {
        if (car_weight > 7000) {
          this.car_tax.tax_price[0] = 4350;
        } else if (car_weight > 6000) {
          this.car_tax.tax_price[0] = 4050;
        } else if (car_weight > 5000) {
          this.car_tax.tax_price[0] = 3750;
        } else if (car_weight > 4500) {
          this.car_tax.tax_price[0] = 3450;
        } else if (car_weight > 4000) {
          this.car_tax.tax_price[0] = 3150;
        } else if (car_weight > 3500) {
          this.car_tax.tax_price[0] = 2850;
        } else if (car_weight > 3000) {
          this.car_tax.tax_price[0] = 2540;
        } else if (car_weight > 2500) {
          this.car_tax.tax_price[0] = 2250;
        } else if (car_weight > 2000) {
          this.car_tax.tax_price[0] = 1950;
        } else if (car_weight > 1750) {
          this.car_tax.tax_price[0] = 1650;
        } else if (car_weight > 1500) {
          this.car_tax.tax_price[0] = 1350;
        } else if (car_weight > 1250) {
          this.car_tax.tax_price[0] = 1050;
        } else if (car_weight > 1000) {
          this.car_tax.tax_price[0] = 900;
        } else if (car_weight > 750) {
          this.car_tax.tax_price[0] = 750;
        } else if (car_weight > 500) {
          this.car_tax.tax_price[0] = 600;
        } else {
          this.car_tax.tax_price[0] = 450;
        }
      } //CT8 CT10 for รถโดยสารประจำทาง (10-19)
      else if (car_type == 'CT8' || car_type == 'CT10') {
        if (car_weight > 7000) {
          this.car_tax.tax_price[0] = 3600;
        } else if (car_weight > 6000) {
          this.car_tax.tax_price[0] = 3400;
        } else if (car_weight > 5000) {
          this.car_tax.tax_price[0] = 3200;
        } else if (car_weight > 4500) {
          this.car_tax.tax_price[0] = 3000;
        } else if (car_weight > 4000) {
          this.car_tax.tax_price[0] = 2800;
        } else if (car_weight > 3500) {
          this.car_tax.tax_price[0] = 2600;
        } else if (car_weight > 3000) {
          this.car_tax.tax_price[0] = 2400;
        } else if (car_weight > 2500) {
          this.car_tax.tax_price[0] = 2200;
        } else if (car_weight > 2000) {
          this.car_tax.tax_price[0] = 1900;
        } else if (car_weight > 1750) {
          this.car_tax.tax_price[0] = 1600;
        } else if (car_weight > 1500) {
          this.car_tax.tax_price[0] = 1300;
        } else if (car_weight > 1250) {
          this.car_tax.tax_price[0] = 1000;
        } else if (car_weight > 1000) {
          this.car_tax.tax_price[0] = 800;
        } else if (car_weight > 750) {
          this.car_tax.tax_price[0] = 450;
        } else if (car_weight > 500) {
          this.car_tax.tax_price[0] = 300;
        } else {
          this.car_tax.tax_price[0] = 150;
        }
      }
    },
    //ฟังก์ชั่นเปลี่ยนเอาค่าภาษีรย.1 เข้า Array เตรียมคำนวณ
    CT1_car_age() {
      if (this.car_tax.car_age > 108) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[5];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[5];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[5];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[5];
      } else if (this.car_tax.car_age > 96) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[4];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[5];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[5];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[5];
      } else if (this.car_tax.car_age > 84) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[3];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[4];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[5];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[5];
      } else if (this.car_tax.car_age > 72) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[2];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[3];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[4];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[5];
      } else if (this.car_tax.car_age > 60) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[1];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[2];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[3];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[4];
      } else if (this.car_tax.car_age > 48) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[1];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[2];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[3];
      } else if (this.car_tax.car_age > 36) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[1];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[2];
      } else if (this.car_tax.car_age > 24) {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[1];
      } else {
        this.car_tax.tax_by_year[0] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[1] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[2] = this.car_tax.tax_price[0];
        this.car_tax.tax_by_year[3] = this.car_tax.tax_price[0];
      }
    },
    changeIsFullYearByStartDate(val, full_year, index) {
      this.form.product[index].start_date = val;
      if (full_year) {
        if (index == 0) {
          this.form.product[index].end_date = moment(
            this.form.product[index].start_date
          )
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss');
        } else {
          this.form.product[index].end_date = moment(
            this.form.product[index].start_date
          )
            .add(365, 'days')
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss');
        }
      }
      // if(index==0) this.cal_ins_not_full_year();
    },
    openUrl() {
      setTimeout(() => {
        window.open(this.form.car_registration_url, '_blank');
      }, 300);
    },
    deleteS3Obj(file_path) {
      this.$alertConfirm({ title: `ต้องการลบรูป ?` }).then(async (result) => {
        if (result.isConfirmed) {
          const config = {
            bucketName: this.aws_bucket,
            dirName: this.dirName,
            region: this.aws_default_region,
            accessKeyId: this.aws_access_key_id,
            secretAccessKey: this.aws_secret_access_key,
            s3Url: this.aws_url,
          };
          let filename = file_path.split('/');
          filename = filename[filename.length - 1];
          const S3Client = new S3(config);
          S3Client.deleteFile(filename)
            .then((response) => {
              this.form.car_registration_url = '';
              const file = document.querySelector('.file');
            })
            .catch((err) => console.error(err));
        }
      });
    },
    uploadS3(file) {
      const config = {
        bucketName: this.aws_bucket,
        dirName: this.dirName,
        region: this.aws_default_region,
        accessKeyId: this.aws_access_key_id,
        secretAccessKey: this.aws_secret_access_key,
        s3Url: this.aws_url,
      };
      const S3Client = new S3(config);
      const newFileName = Math.random().toString().slice(2);
      return new Promise((fulfill, reject) => {
        S3Client.uploadFile(file, newFileName)
          .then((data) => fulfill(data))
          .catch((err) => reject(err));
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      const mb = 1048576; // 1 MB
      if (!file) return;
      if (file.size > mb * 0.5) {
        alert('Allowed file size exceeded. (Max. ' + mb * 0.5 + ' MB)');
        return;
      }
      this.file = file;
      this.uploading = true;

      this.uploadS3(file).then((res) => {
        this.uploading = false;
        this.form.car_registration_url = res.location;
      });
    },
    async getUserGroup() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
            console.log("No getGroupPermissions")
        };

        this.permission.show_phone_number = this.$store.state.group_permissions.show_phone_number;
        
        if (this.permission.show_phone_number == 0) {
          this.showPhoneNumber = false;
          this.toggleShowPhoneNumber = false;
        } else {
          this.showPhoneNumber = true;
          this.toggleShowPhoneNumber = true;
        };
      } catch (error) {
        this.showPhoneNumber = true;
        this.toggleShowPhoneNumber = true;
        console.error('Error in getUserGroup:', error);
      };
    },
  },
  watch: {
    customer_name_search: function (val) {
      if (!this.loading) {
        val !== this.customer && this.handleCustomer();
      }
    },
    'form.customer_phone_number': function (val){
      if(this.showPhoneNumber){
        return
      }
      if (this.transTimeout) {
        clearTimeout(this.transTimeout);
        this.transTimeout = null;
      }
      if(val.length == 0 ){
        this.toggleShowPhoneNumber = true
      } else if(val.length >= 10){
        this.transTimeout = setTimeout( () =>{
          this.toggleShowPhoneNumber = false
        }, 5000);
      }
    },
    isEditFilterExpressProduct: function (newVal, oldVal) {
      if (newVal && !this.loading) {
        this.getExpressProduct();
      }
    },
  },
  computed: {
    differrence_price() {
      return (
        Math.floor(
          (Math.abs(this.form.original_document_price - (this.total_price - this.total_discount + (this.vat_price))) +
            Number.EPSILON) *
            100
        ) / 100
      );
    },
    total_price() {
      let price = 0;
      this.form.product.forEach((product) => {
        price += parseFloat(product.product_price || 0);
      });
      return price;
    },
    total_discount() {
      let discount = 0;
      this.form.product.forEach((product) => {
        discount += parseFloat(product.discount || 0);
      });
      // let temp_discount = 0;
      // this.form.temp_product.forEach(product => {
      //   discount += parseFloat(product.discount || 0);
      // });
      return Math.abs(discount);
    },
    vat_price() {
      let price = 0;
      // let temp_price = 0;
      this.form.product.forEach((product) => {
        if (
          product.category_id != 'PC1' &&
          product.category_id != 'PC2' &&
          product.vat == 1
        )
          price += parseFloat(product.vat_price || 0);
      });
      // this.form.temp_product.forEach((product) => {
      //   if (
      //     product.category_id != 'PC1' &&
      //     product.category_id != 'PC2' &&
      //     product.vat == 1
      //   )
      //     temp_price += parseFloat(product.vat_price || 0);
      // });
      return Math.abs(price);
    },
    net_price() {
      if (this.list.setting) {
        let total_price =
          this.total_price - this.total_discount + this.vat_price;
        return parseFloat(
          this.list.setting.reduce_bill_scrap
            ? Math.floor(total_price).toFixed(2)
            : parseFloat(total_price).toFixed(2)
        );
      } else return 0;
    },
  },
};
</script>

<style lang="scss">
.border-grey {
  border: solid rgb(211, 211, 211) 1px !important;
  border-radius: 5px;
  padding: 10px;
}
.border-white {
  border: solid rgb(255, 255, 255) 1px !important;
  border-radius: 5px;
  padding: 10px;
}
.swal2-icon.swal2-warning {
  border-color: #f27474 !important;
  icon-color: #f27474 !important;
}
.table-style {
  vertical-align: baseline !important;
}
.btn-table-style {
  vertical-align: top !important;
}
</style>
